/**
 * Reducers produce the state of the application.
 * Reducers know when to change state is by sending a signal to the store.
 * The signal is an action. "Dispatching an action" is the process of sending out a signal.
 *
 * Important thing to note is you dont change an existing state. You make a copy of that state with current plus new data.
 */

import { combineReducers } from 'redux';

// If adding a new app, import it, then add it to the `combineReducers` object
import rootReducer from './reducers/index';
import lcertReducer from './reducers/lettersofcertification';
import equipmentReducer from './reducers/equipment';
import redistributionReducer from './reducers/redistribution';
import milestoneReducer from './reducers/milestone';
import trainingReducer from './reducers/training';

export default combineReducers({
  rootReducer,
  lcert: lcertReducer,
  equipment: equipmentReducer,
  redistribution: redistributionReducer,
  milestone: milestoneReducer,
  training: trainingReducer,
});
