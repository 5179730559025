const useStyles = () => ({
  baseStyle: {
    display: 'flex',
    width: '100%',
    padding: '32px 48px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'space-between',
    alignSelf: 'stretch',
    gap: '8px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  focusedStyle: {
    borderColor: '#2196f3',
  },
  acceptStyle: {
    borderColor: '#2196f3',
  },
  rejectStyle: {
    borderColor: '#ff1744',
  },
  fileUploadTitle: {
    color: '#000',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  fileUploadSubTitle: {
    color: '#000',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  btnContainer: {
    position: 'absolute',
    top: 6,
    right: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    padding: '4px 8px',
    borderRadius: '4px',
    bgcolor: '#E2EAFF',
    color: '#1C43B1',
    fontSize: '14px',
    cursor: 'pointer',
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  errorText: {
    color: '#ff1744',
    fontWeight: 600,
    fontSize: '16px',
  },
});
export default useStyles;
