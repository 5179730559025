import { createTheme } from '@mui/material/styles';
import themeConfig from './config';

const pxToRem = (n) => `${n / themeConfig.rootFontSizePx}rem`;
const theme = createTheme();

const typography = {
  h1: {
    fontWeight: 600,
    fontSize: pxToRem(34),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(24),
    },
  },
  h2: {
    fontWeight: 600,
    fontSize: pxToRem(24),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(18),
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: pxToRem(20),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(16),
    },
  },
  h4: {
    fontWeight: 500,
    fontSize: pxToRem(20),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(16),
    },
  },
  h5: {
    fontWeight: 600,
    fontSize: pxToRem(16),
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(14),
    },
  },
  h6: {
    fontWeight: 600,
    fontSize: pxToRem(14),
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(12),
    },
  },
  body1: {
    fontSize: pxToRem(14),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(12),
    },
  },
  body2: {
    fontSize: pxToRem(12),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(12),
    },
  },
  fontSize: themeConfig.rootFontSizePx,
};

export default typography;
