const PREFIX = 'redistribution';

const FIELDS = {
  'redistribution/selectedOrganization': 'selectedOrganization',
  'redistribution/selectedContributor': 'selectedContributor',
};

const InitialState = {
  selectedOrganization: '',
  selectedContributer: '',
};

function RedistributionReducer(state = InitialState, action) {
  const newState = {};

  if (!FIELDS[action.type]) {
    return state;
  }

  newState[FIELDS[action.type]] = action.payload;
  return Object.assign({}, state, newState);
}

export default RedistributionReducer;
