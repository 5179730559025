const useStyles = () => ({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    padding: 0,
    minWidth: '320px',
  },
  modalContainer: {
    display: 'flex',
    width: '471px',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    px: '24px',
    width: '100%',
    minHeight: '100px',
    justifyContent: 'center',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '16px',
  },
  footerWrapper: {
    display: 'flex',
    gap: '8px',
  },
});

export default useStyles;
