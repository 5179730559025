/**
 * Contains the basic logic for the more advanced reducer that was used in the Inventory App
 */

export default function reduce(action, state, newState, prefix, fieldsByName) {
  if (action.type.indexOf(prefix) === 0) {
    const act = action.type.substring(prefix.length + 1);
    const type = act.split('_')[0] || '';
    const name = act.substring(type.length + 1);
    const arr_field = fieldsByName[name]?.array_field || 'pvDataID';
    if (type === 'set' && fieldsByName[name] && fieldsByName[name].setter) {
      // This is directly setting a field
      newState[fieldsByName[name].field] = action.payload;
    } else if (type === 'upsert' && fieldsByName[name] && fieldsByName[name].array) {
      let tempState = [].concat(state[fieldsByName[name].field]);
      let found = false;
      for (let i = 0; i < tempState.length; i++) {
        if (String(tempState[i][arr_field]) === String(action.payload[arr_field])) {
          // This item needs to be updated
          tempState[i] = action.payload;
          found = true;
          break;
        }
      }
      if (!found) {
        tempState.push(action.payload);
      }
      newState[fieldsByName[name].field] = tempState;
    } else if (type === 'remove' && fieldsByName[name] && fieldsByName[name].array) {
      let tempState = [].concat(state[fieldsByName[name].field]);
      for (let i = 0; i < tempState.length; i++) {
        if (String(tempState[i][arr_field]) === String(action.payload[arr_field])) {
          // This item needs to be updated
          tempState.splice(i, 1);
          break;
        }
      }
      newState[fieldsByName[name].field] = tempState;
    } else if (type === 'concat' && fieldsByName[name] && fieldsByName[name].array) {
      // Concatenate array with one passed in
      let tempState = [].concat(state[fieldsByName[name].field]);
      tempState = tempState.concat(action.payload);
      newState[fieldsByName[name].field] = tempState;
    }
  }
}

export function getFieldsByName(fieldsArr) {
  const fieldsByName = {};

  for (const item of fieldsArr) {
    let name = item.name || item.field;
    fieldsByName[name] = item;
  }

  return fieldsByName;
}
