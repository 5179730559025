import React from 'react';
import { NormalTextField, RequiredTextField } from '@zawarski/palmetto-ui-components';

const TextField = ({ errMsg, helperTxt, required, ...props }) => {
  const hasError = errMsg !== undefined && Object.prototype.hasOwnProperty.call(errMsg, 'message');
  const msgText = hasError ? errMsg.message : helperTxt;
  if (required) {
    return (
      <RequiredTextField
        {...props}
        style={{ marginLeft: 0, marginRight: 0 }}
        fullWidth
        InputLabelProps={{ shrink: true, color: hasError ? 'error' : 'secondary' }}
        helperText={msgText}
        onFocus={(e) => e.target.select()}
      />
    );
  }
  return (
    <NormalTextField
      {...props}
      style={{ marginLeft: 0, marginRight: 0 }}
      variant={props?.variant || 'standard'}
      fullWidth
      InputLabelProps={{ shrink: true, color: hasError ? 'error' : 'secondary' }}
      helperText={msgText}
      onFocus={(e) => e.target.select()}
    />
  );
};

export default TextField;
