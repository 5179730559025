const TrainingTypes = [
  {
    key: 'medical',
    value: 'Medical Service Training',
  },
  {
    key: 'radiation',
    value: 'Radiation Fundamentals Training',
  },
  {
    key: 'state-player',
    value: 'State Player Training',
  },
  {
    key: 'initial-planning',
    value: 'Initial planning meeting',
  },
  {
    key: 'mid-planning',
    value: 'Mid-term planning meeting',
  },
  {
    key: 'final-planning',
    value: 'Final planning meeting',
  },
  {
    key: 'dress-rehearsal',
    value: 'Dress Rehearsal FSE',
  },
  {
    key: 'full-scale-exercise',
    value: 'Full Scale Exercise',
  },
];

export default TrainingTypes;
