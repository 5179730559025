/**
 * Manages the Letters of Certification state.
 *
 * Note: the action names follow this pattern: <prefix>/<set/upsert/remove>_<field_name>
 */

import { PREFIX, FIELDS } from '../const/lettersofcertification';
import reduce, { getFieldsByName } from '../util/baseReducer';

const initialState = {
  loading: false,
  hasUpdate: false,
  initialized: false,
  // Data for views
  dataset: [],
  currentEntry: {},
};

const FIELDS_BY_NAME = getFieldsByName(FIELDS);

export default function inventoryReducer(state = initialState, action) {
  const newState = {};

  reduce(action, state, newState, PREFIX, FIELDS_BY_NAME);

  return Object.assign({}, state, newState);
}
