const useStyles = () => ({
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    bgcolor: '#fff',
    padding: '4px 16px',
  },
  input: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    flex: '1 0 0',
  },
  btn: {
    bgcolor: '#FAFAFA',
    padding: '12px',
    borderRadius: '4px',
    color: '#1A1A1A',
    cursor: 'pointer',
    '&:hover': {
      color: '#2961FD',
    },
  },
});
export default useStyles;
