import { PREFIX } from '../const/training';
import { apiClient } from '../../common/axios/axios_instance';

export const saveEntryClass = (payload, cb) => {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: `${PREFIX}/set_saving`, payload: true });
      const { user } = getState().rootReducer;
      const toSave = {};
      let toRet = {};
      let resp = null;

      Object.assign(toSave, payload, {
        pvUserID: user.id,
      });

      if (!toSave.pvDataID) {
        // *: Action POST / CREATE
        resp = await apiClient.post(
          `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/training/class`,
          toSave,
        );
        toRet = resp.data;
      } else {
        // *: Action PUT / UPDATE
        resp = await apiClient.put(
          `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/training/class/${toSave.pvDataID}`,
          toSave,
        );
        toRet = resp.data;
      }
      dispatch({ type: `${PREFIX}/set_saving`, payload: false });
      if (cb) {
        cb(null, toRet);
      }
    } catch (error) {
      console.log('Error entry class', error);
      dispatch({ type: `${PREFIX}/set_saving`, payload: false });
      if (cb) {
        cb(error);
      }
    }
  };
};

export const deleteEntryClass = async (id) => {
  await apiClient.delete(
    `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/training/class/${id}`,
  );
};

// export const getAllClass = (callback) => {
//   return async function (dispatch) {
//     try {
//       dispatch({ type: `${PREFIX}/set_loading`, payload: true });
//       const response = await apiClient.get(
//         `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/training/class`,
//       );
//       const data = await response.data;
//       dispatch({ type: `${PREFIX}/set_training_class_dataset`, payload: data });
//       dispatch({ type: `${PREFIX}/set_loading`, payload: false });
//       if (callback) {
//         callback(null, data);
//       }
//     } catch (error) {
//       console.log(error);
//       dispatch({ type: `${PREFIX}/set_loading`, payload: false });
//       if (callback) {
//         callback(error);
//       }
//     }
//   };
// };

export const getOneClass = (groupId, callback) => {
  return async function (dispatch) {
    try {
      dispatch({ type: `${PREFIX}/set_loading`, payload: true });
      const response = await apiClient.get(
        `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/training/class/${groupId}`,
      );
      const data = await response.data;
      callback && callback(null, data);
    } catch (error) {
      console.log(error);
      dispatch({ type: `${PREFIX}/set_loading`, payload: false });
      if (callback) {
        callback(error);
      }
    }
  };
};

export const saveEntryAttendee = (payload, callback) => {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: `${PREFIX}/set_saving`, payload: true });
      const { user } = getState().rootReducer;
      const toSave = {};
      let toRet = {};
      let resp = null;

      Object.assign(toSave, payload, {
        pvUserID: user && Object.prototype.hasOwnProperty.call(user, 'id') ? user?.id : null,
      });

      if (!toSave.pvDataID) {
        // *: Action POST / CREATE
        let url = `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/training/attendee`;
        if (payload.register) {
          // url = `${process.env.REACT_APP_SLS_LOOPBACK_API_REGISTER_ENDPOINT}/api/rep/training/register`;
          url = `https://zd7y6vbccl.execute-api.us-east-1.amazonaws.com/prod/register/api/rep/training/register`;
          delete payload.register;
        }
        resp = await apiClient.post(url, toSave);
        toRet = resp.data;
      } else {
        // *: Action PUT / UPDATE
        resp = await apiClient.put(
          `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/training/attendee`,
          toSave,
        );
        toRet = resp.data;
      }

      dispatch({ type: `${PREFIX}/set_saving`, payload: false });
      if (callback) {
        callback(null, toRet);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: `${PREFIX}/set_saving`, payload: false });
      if (callback) {
        callback(error);
      }
    }
  };
};

export const getLatestTraining = (callback) => {
  return async function (dispatch) {
    try {
      // const response = await apiClient.get(
      //   `${process.env.REACT_APP_SLS_LOOPBACK_API_REGISTER_ENDPOINT}/api/rep/training/latest`,
      // );
      const response = await apiClient.get(
        `https://zd7y6vbccl.execute-api.us-east-1.amazonaws.com/prod/register/api/rep/training/latest`,
      );
      const data = await response.data;
      if (callback) {
        callback(null, data);
      }
    } catch (error) {
      console.log(error);
      if (callback) {
        callback(error);
      }
    }
  };
};

export const getTrainingStatus = (year, callback) => {
  return async function () {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/training/status?year=${year}`,
      );
      const data = response.data?.dataset || [];
      if (callback) {
        callback(null, data);
      }
    } catch (error) {
      console.log(error);
      if (callback) {
        callback(error);
      }
    }
  };
};

export const getTrainingStatusYears = (callback) => {
  return async function () {
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/training/status/years`,
      );
      const data = response.data || [];
      if (callback) {
        callback(null, data);
      }
    } catch (error) {
      console.log(error);
      if (callback) {
        callback(error);
      }
    }
  };
};

export const updateTrainingStatus = (payload, callback) => {
  return async function (dispatch, getState) {
    try {
      const { user } = getState().rootReducer;
      const toSave = {};
      let toRet = {};
      let resp;

      Object.assign(toSave, payload, {
        pvUserID: user && Object.prototype.hasOwnProperty.call(user, 'id') ? user?.id : null,
      });

      resp = await apiClient.put(
        `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/training/status`,
        toSave,
      );
      toRet = resp.data;
      if (callback) {
        callback(null, toRet);
      }
    } catch (error) {
      console.log(error);
      if (callback) {
        callback(error);
      }
    }
  };
};
