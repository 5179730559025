import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { ApplicationSpinner } from '@zawarski/palmetto-ui-components';
import useStyles from './Loading.styles';

const Loading = (props) => {
  const classes = useStyles();
  const [text, setText] = useState(props.text);

  return (
    <Box sx={classes.container}>
      <ApplicationSpinner />
      <Box sx={classes.wrapper}>{text}</Box>
    </Box>
  );
};

Loading.prototype = {
  text: PropTypes.string,
};

Loading.defaultProps = {
  text: 'Loading, please wait...',
};

export default Loading;
