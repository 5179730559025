import PropTypes from 'prop-types';
import React from 'react';
import RSelect, { components } from 'react-select';
import { MdRemoveCircleOutline as RemoveCircleIcon } from 'react-icons/md';

const MultiSelectField = ({ white = false, ...props }) => {
  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <RemoveCircleIcon sx={{ fontSize: '1em' }} />
      </components.MultiValueRemove>
    );
  };

  const colorStyles = {
    control: (styles) =>
      props.error
        ? { ...styles, backgroundColor: `${white ? '#ffff' : '#fafafa'}`, borderColor: 'red' }
        : { ...styles, backgroundColor: `${white ? '#ffff' : '#fafafa'}` },
    option: (styles) => {
      return { ...styles };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: '#D8D8D8',
        borderRadius: '2px',
      };
    },
    multiValueLabel: (styles) => {
      return { ...styles, color: '#1A1A1A', fontWeight: '700' };
    },
    multiValueRemove: (styles) => {
      return {
        ...styles,
        color: '#1A1A1A',
        borderRadius: '2px',
        cursor: 'pointer',
        ':hover': { color: '#01195B' },
      };
    },
  };

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName('selectDropdown')[0];
      selectedEl?.scrollIntoView();
    }, 15);
  };

  return (
    <RSelect
      {...props}
      style={{ width: '100%' }}
      isDisabled={props.disabled}
      isMulti
      hideSelectedOptions={true}
      options={props.options}
      components={{ MultiValueRemove }}
      styles={colorStyles}
      value={props.value ? props.value : null}
      // eslint-disable-next-line
      onChange={(selected) => {
        return selected?.length && selected.find((option) => option.value === 'all')
          ? props.onChange(props.options.slice(1))
          : props.onChange(selected);
      }}
      className={'selectDropdown'}
      onMenuOpen={onMenuOpen}
    />
  );
};

MultiSelectField.propTypes = {
  disabled: PropTypes.bool,
  white: PropTypes.bool,
  error: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

export default MultiSelectField;
