const useStyles = (theme) => ({
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '56px',
    py: '5px',
    width: '100%',
  },
  input: {
    padding: '0 !important',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '1px !important',
      lineHeight: '1.5',
      fontSize: '1rem',
      width: '100%',
    },
  },
  mask: {
    height: '100%',
    color: 'rgba(0, 0, 0, 0.42)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    pr: '2px',
    textWrap: 'nowrap',
  },
});
export default useStyles;
