import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './ElectronicApproveModal.styles';
import { Button, Modal, Typo } from '../index';
import { Box, IconButton } from '@mui/material';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import { MdClose } from 'react-icons/md';

const ElectronicApproveModal = (props) => {
  const classes = useStyles();

  const handleCloseModal = () => {
    if (props.isSubmitting) return null;
    props.setOpen && props.setOpen(false);
  };

  return (
    <Modal sx={{ overflow: 'hidden' }} {...props} contentStyle={classes.modalContent}>
      <Box sx={classes.modalContainer}>
        <HeaderComponent
          title={props.title}
          icon={
            <IconButton onClick={() => handleCloseModal()}>
              <MdClose className='icon' />
            </IconButton>
          }
        />
        <Box sx={classes.content}>
          <Typo>{props.message}</Typo>
        </Box>
        <Box sx={classes.footer}>
          <Box sx={classes.footerWrapper}>
            <Button neutral={true} onClick={() => handleCloseModal()}>
              CANCEL
            </Button>
            <Button
              progress={props.isSubmitting}
              onClick={!props.isSubmitting ? () => props.onSubmit() : undefined}>
              I ACCEPT
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

ElectronicApproveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
};

ElectronicApproveModal.defaultProps = {
  title: 'Submit Certification',
  message: `By selecting the "I Accept" button, you are signing this Agreement electronically. You
            agree your electronic signature is the legal equivalent of your manual signature on this
            Agreement.`,
};

export default ElectronicApproveModal;
