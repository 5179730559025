/**
 * Contains Equipment Redux Constants
 */

export const PREFIX = 'equipment';

export const FIELDS = [
  {
    name: 'loading', // Name of the field to expose in the reducer and for dispatch (takes name from field if not included)
    field: 'loading', // Name of the field in the state object
    setter: true, // Add setters
    array: false, // Add upsert and remove for arrays
    // array_field: "pvDataID", // Specify the field to check in the array (defaults to pvDataID)
  },
  {
    name: 'has_update',
    field: 'hasUpdate',
    setter: true,
  },
  {
    name: 'equipment_dataset',
    field: 'equipmentDataset',
    setter: true,
    array: true,
  },
  {
    name: 'calibration_dataset',
    field: 'calibrationDataset',
    setter: true,
    array: true,
  },
  {
    name: 'equipment_current_entry',
    field: 'equipmentCurrentEntry',
    setter: true,
  },
  {
    name: 'calibration_current_entry',
    field: 'calibrationCurrentEntry',
    setter: true,
  },
  {
    name: 'selected_tab',
    field: 'selectedTab',
    setter: true,
  },
  {
    name: 'organization_id',
    field: 'organizationID',
    setter: true,
  },
  {
    name: 'page',
    field: 'page',
    setter: true,
  },
  {
    name: 'page_limit',
    field: 'pageLimit',
    setter: true,
  },
  {
    name: 'initial_page_total',
    field: 'initialPageTotal',
    setter: true,
  },
  {
    name: 'page_total',
    field: 'pageTotal',
    setter: true,
  },
  // {
  //     name: "history",
  //     field: "history",
  //     setter: true
  // },
  // {
  //     name: "comments",
  //     field: "comments",
  //     setter: true,
  //     array: true,
  //     array_field: "pvCommentID"
  // }
];
