import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Box, Checkbox } from '@mui/material';
import TextField from '../TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from './SectionSelect.styles';

const SectionSelect = (props) => {
  const classes = useStyles();
  return (
    <Box sx={props.selectStyle ? props.selectStyle : classes.body}>
      <Box sx={classes.chkBox}>
        <Controller
          control={props.control}
          name={props.checkedName}
          render={({ field: { onChange, value } }) => (
            <Checkbox onChange={onChange} checked={value} disabled={!props.isEdit} />
          )}
        />
        <Controller
          name={props.name}
          control={props.control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              error={Boolean(error)}
              errMsg={error}
              value={value}
              onChange={onChange}
              InputProps={{
                readOnly: !props.isEdit,
                ...props.InputProps,
              }}
              helperTxt={error ? error.message : ''}
            />
          )}
        />
      </Box>
      <Box hidden={!props.isEdit} sx={classes.btn}>
        <DeleteIcon fontSize='small' onClick={() => props.onRemoveEntry(props.index)} />
      </Box>
    </Box>
  );
};

SectionSelect.prototype = {
  handleAction: PropTypes.func,
  isEdit: PropTypes.bool,
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  checkedName: PropTypes.string,
  selectStyle: PropTypes.object,
  item: PropTypes.object,
  control: PropTypes.any.isRequired,
  onRemoveEntry: PropTypes.func.isRequired,
};

SectionSelect.defaultProps = {
  isEdit: false,
  name: '',
  checkedName: '',
};

export default SectionSelect;
