import React from 'react';
import Typography from '@mui/material/Typography';

const Typo = ({ variant = 'body1', ...props }) => {
  return (
    <Typography {...props} variant={variant}>
      {props.children}
    </Typography>
  );
};

export default Typo;
