const useStyles = (theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    padding: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    background: '#EBEBEB',
    [theme.breakpoints.down('md')]: {
      padding: '24px',
    },
  },
  header: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'center',
    background: '#FFF',
    boxShadow: '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)',
    width: '672px',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '672px',
    background: '#FFF',
    boxShadow: '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '24px',
  },
  btnWrap: {
    display: 'flex',
    width: '100%',
  },
});

export default useStyles;
