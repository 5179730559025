import React from 'react';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Typo from '../Typo';

const FormGroup = ({
  label,
  color = 'textSecondary',
  error,
  fontWeight = 400,
  variant = 'body2',
  ...props
}) => {
  return (
    <Box {...props}>
      <Typo color={color} variant={variant} fontWeight={fontWeight}>
        {label}
      </Typo>
      {props.children}
      <Box
        sx={{
          display: `${error ? 'flex' : 'none'}`,
          alignItems: 'center',
          textAlign: 'center',
          marginTop: '4px',
        }}>
        <FormHelperText error={!!error} sx={{ mt: 0 }}>
          {error?.message}
        </FormHelperText>
      </Box>
    </Box>
  );
};

export default FormGroup;
