import React from 'react';
import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
// import { useTheme } from '@mui/material';

const ToggleButton = (props) => {
  // const theme = useTheme();
  // const selectedColor = theme.palette.primary.main;
  const StyledToggleButton = styled(MuiToggleButton)(({ theme }) => ({
    color: '#1F1F1F',
    borderColor: 'transparent',
    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px !important',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&:hover': {
      backgroundColor: '#F0F0F0',
      // borderColor: theme.palette.primary.main,
    },
  }));

  return <StyledToggleButton {...props} />;
};

// ToggleButton.propTypes = {
//   ...ToggleButtonProps,
// };

export default ToggleButton;
