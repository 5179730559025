import { ALL_TYPES } from '../const/action-types.js'; // All action types are here

const initialState = {
  appLoading: true,
  loginState: false,
  // saving: false,
  // appType: null,
  userSettings: null,
  selectedGroup: {},
  selectedGroupName: '',
  palmettoUserAccount: {},
  appPermissions: {},
  isCurrentUserSubgroupUser: false,
  currentUserSubgroup: {},
  groups: [],
  apps: {},
  incidents: [],
  currentEntry: {},
  dataset: [],
  appServiceID: 0,
  countyName: null,
};

const initialStateFileLibrary = {
  ...initialState,
  appLoading: true,
  loginState: false,
  saving: false,
  appType: null,
  repUserType: null,
  userSettings: null,
  selectedGroup: null,
  selectedGroupName: '',
  palmettoUserAccount: {},
  appPermissions: {},
  isCurrentUserSubgroupUser: false,
  currentUserSubgroup: {},
  currentEntry: {},
  groups: [],
  dataset: [],
  datasetByFolder: {},
  parentID: null,
  appServiceID: 0,
};

// Handles Setting the state outright
export default function rootReducer(state = initialStateFileLibrary, action) {
  const newState = {};
  if (ALL_TYPES[action.type]) {
    newState[ALL_TYPES[action.type]] = action.payload;
    return Object.assign({}, state, newState);
  }
  return state; // Always return the old state in the default case
}

/**
 * Reducers produce the state of the application.
 * Reducers know when to change state is by sending a signal to the store.
 * The signal is an action. "Dispatching an action" is the process of sending out a signal.
 *
 * Important thing to note is you don't change an existing state. You make a copy of that state with current plus new data.
 */
