import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import useStyles from './Navigation.styles';

const Navigation = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const tempOpts = props.options;
    for (let i = 0; i < tempOpts.length; i++) {
      if (tempOpts[i].id === props.selectedItem) {
        if (selectedIndex !== i) {
          setSelectedIndex(i);
        }
        break;
      }
    }
  }, [props.selectedItem, props.options]);

  const handleChange = (idx) => {
    setSelectedIndex(() => idx);
    props.setIndex(() => idx);
    props.onChange(props.options[idx].id);
  };

  return (
    <Box sx={props.customRootStyle ? props.customRootStyle : classes.root}>
      <Tabs
        sx={classes.tabs}
        value={selectedIndex}
        variant='fullWidth'
        indicatorColor='transparent'>
        {props.options.map((item, idx) => {
          return (
            <Tab
              key={item.id}
              iconPosition='start'
              label={item.title}
              icon={item.icon}
              onClick={() => handleChange(idx)}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

Navigation.proptypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.node,
    }),
  ),
  selectedItem: PropTypes.string,
  setSelectedItem: PropTypes.func,
  onChange: PropTypes.func,
  index: PropTypes.number,
  setIndex: PropTypes.func,
  customRootStyle: PropTypes.object,
};

export default Navigation;
