import React from 'react';
import PropTypes from 'prop-types';
import { StatusComponent } from '@zawarski/palmetto-ui-components';
import '../../../styles/custom_status.css';

export default function StatusTag({ text, ...props }) {
  const Status2ClassObj = {
    'changes requested': ' s-container  status-text changes-requested-status ',
    submitted: ' s-container status-text submitted-status ',
    approved: '   s-container status-text approved-status ',
    incomplete: ' s-container  status-text incomplete-status ',
  };

  return (
    <StatusComponent
      statusToClass={props.statusToClass ? props.statusToClass : Status2ClassObj}
      text={text}
    />
  );
}

StatusTag.propTypes = {
  text: PropTypes.string,
  statusToClass: PropTypes.object,
};
