/**
 * Contains Training Redux Constants
 */

export const PREFIX = 'training';

export const FIELDS = [
  {
    name: 'loading', // Name of the field to expose in the reducer and for dispatch (takes name from field if not included)
    field: 'loading', // Name of the field in the state object
    setter: true, // Add setters
    array: false, // Add upsert and remove for arrays
  },
  {
    name: 'saving',
    field: 'saving',
    setter: true,
    array: false,
  },
  {
    name: 'training_class_dataset',
    field: 'trainingClassDataset',
    setter: true,
    array: true,
  },
  {
    name: 'training_class_current_entry',
    field: 'trainingClassCurrentEntry',
    setter: true,
  },
  {
    name: 'training_attendee_dataset',
    field: 'trainingAttendeeDataset',
    setter: true,
    array: true,
  },
  {
    name: 'training_attendee_current_entry',
    field: 'trainingAttendeeCurrentEntry',
    setter: true,
  },
];
