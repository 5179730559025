import { styled } from '@mui/material';
import MuiToggleButton from '@mui/material/ToggleButton';

const useStyles = () => ({
  rootGroup: {
    bgcolor: '#F0F0F0',
    borderRadius: 0,
    // padding: '2px',
    // border: '1px solid #E0E0E0',
  },
});

export const ToggleButton = styled(MuiToggleButton)({
  borderRadius: 0,
  minWidth: '42px',
  textTransform: 'unset !important',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: 'var(--pcl-primary-background-color)',
  },
});

export default useStyles;
