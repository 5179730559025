/**
 * Contains the Letters of Certification Redux Constants
 */

export const PREFIX = 'lcert';

export const FIELDS = [
  {
    name: 'loading', // Name of the field to expose in the reducer and for dispatch (takes name from field if not included)
    field: 'loading', // Name of the field in the state object
    setter: true, // Add setters
    array: false, // Add upsert and remove for arrays
    // array_field: "pvDataID", // Specify the field to check in the array (defaults to pvDataID)
  },
  {
    name: 'has_update',
    field: 'hasUpdate',
    setter: true,
  },
  {
    name: 'dataset',
    field: 'dataset',
    setter: true,
    array: true,
  },
  {
    name: 'current_entry',
    field: 'currentEntry',
    setter: true,
  },
  // {
  //     name: "history",
  //     field: "history",
  //     setter: true
  // },
  // {
  //     name: "comments",
  //     field: "comments",
  //     setter: true,
  //     array: true,
  //     array_field: "pvCommentID"
  // }
];
