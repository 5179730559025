import { apiClient } from '../../common/axios/axios_instance';

export const getUsersSavedIncidents = async (payload) => {
  let selectedIncSettings = '[]';

  for (let i = 0; i < payload.settings.length; i++) {
    if (payload.settings[i].pvSettingType === 'selectedIncidents') {
      selectedIncSettings = payload.settings[i].pvSettingValue;
    }
  }

  if (selectedIncSettings) {
    let incidentIds = selectedIncSettings || '[]';
    let savedIncidents = [];
    //Sort and remove duplicates in case of bad data
    let obj = JSON.parse(incidentIds);
    //Remove duplicates (JIC)
    obj.forEach(function (item) {
      if (savedIncidents.indexOf(item) < 0) {
        savedIncidents.push(item);
      }
    });

    let incidentFilter = determineIncidentsFilter(payload);
    let incidentsRes = await apiClient.get(
      `${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/incidents?filter=${JSON.stringify(
        incidentFilter,
      )}`,
    );

    let incidents = parseSelectedIncidents(
      incidentsRes.data || [],
      payload.selectedGroupID,
      payload.accountInfo,
      savedIncidents,
    );

    return incidents;
  }

  return [];
};

function _checkExerciseStatus(incident) {
  if (incident && incident.pvExercise) {
    // Return exercise which are in progress
    if (incident.pvExerciseStatus && incident.pvExerciseStatus === 'In Progress') {
      return true;
    }
  } else {
    //since this is not an exercise treat it as a normal incident
    return true;
  }
}

function parseSelectedIncidents(
  allIncidents,
  activeGroup,
  palmettoUserAccount,
  savedIncidentsInSettings,
) {
  let defaultIncidents = [];
  activeGroup = activeGroup || palmettoUserAccount.account2groups[0].pvGroupID;

  let data = [];

  for (let i = 0; i < allIncidents.length; i++) {
    for (let j = 0; j < allIncidents[i].incident2groups.length; j++) {
      if (allIncidents[i].incident2groups[j].pvGroupID === activeGroup) {
        data.push(allIncidents[i]);
        break;
      }
    }
  }

  // Find the default incidents

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].incident2groups.length; j++) {
      if (
        data[i].incident2groups[j].pvGroupID === activeGroup &&
        data[i].incident2groups[j].pvAutoAssign
      ) {
        defaultIncidents.push(data[i]);
        break;
      }
    }
  }

  let updatedIncidents = [];
  let filledIncidents = [];

  //Find the common incidents
  for (let i = 0; i < savedIncidentsInSettings.length; i++) {
    for (let j = 0; j < data.length; j++) {
      if (savedIncidentsInSettings[i] === data[j].pvIncidentID) {
        filledIncidents.push(data[j]);
        break;
      }
    }
  }

  updatedIncidents = updatedIncidents.concat(filledIncidents);
  updatedIncidents = updatedIncidents.concat(defaultIncidents);

  // here we are getting rid of exercise if they are not started
  let arr = [];
  for (let i = 0; i < updatedIncidents.length; i++) {
    var incident = updatedIncidents[i];
    if (_checkExerciseStatus(incident)) {
      arr.push(incident);
    }
  }

  // Remove duplicates
  var uniqueIncidents = [],
    track = {};
  for (var i = 0; i < arr.length; i++) {
    if (!track[arr[i].pvIncidentID]) {
      track[arr[i].pvIncidentID] = arr[i].pvIncidentID;
      uniqueIncidents.push(arr[i]);
    }
  }

  return uniqueIncidents;
}

function determineIncidentsFilter(payload) {
  var filter = {};
  filter = {
    where: {
      and: [
        {
          pvStatus: {
            neq: 'CLOSED',
          },
        },
        {
          or: [],
        },
      ],
    },
  };
  if (
    payload.selectedGroupID ||
    (payload.accountInfo &&
      payload.accountInfo.account2groups &&
      payload.accountInfo.account2groups.length > 0)
  ) {
    var activeGroup = payload.selectedGroupID || payload.accountInfo.account2groups[0].pvGroupID;
    filter.where.and[1].or.push({ pvGroupID: activeGroup });
  } else {
    filter.where.and[1].or.push({ pvGroupID: -1 });
  }

  filter.include = {
    relation: 'incident2groups',
    scope: { relation: 'incidents' },
  };
  return filter;
}
