/**
 * Contains the Dialog used by the state to request changes in a Letter of Certification
 */

import { Checkbox, CircularProgress, Dialog, FormControlLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { HeaderComponent, PrimaryButton } from '@zawarski/palmetto-ui-components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import '../../../styles/common.css';
import { TextField } from '../../utilities/ComponentWrappers';

export default function RequestChangeDialog({
  open,
  setOpen,
  results: propResults,
  onSubmit,
  saving,
  ...props
}) {
  const [validate, setValidate] = useState(false);
  const [results, setResults] = useState({});

  useEffect(() => {
    if (propResults && propResults !== results) {
      setResults(Object.assign({}, results));
    }
  }, [propResults]);

  const handleClose = () => {
    setOpen(false);
    setValidate(false);
  };

  const handleSubmit = () => {
    setValidate(false);

    onSubmit(results);
  };

  const onResultsChange = (field, value) => {
    const temp = {};
    Object.assign(temp, results);
    temp[field] = value;
    setResults(temp);
  };

  const componentParams = {
    results,
    onResultsChange,
    validate,
  };

  let [reasons, setReasons] = useState('');

  const handleSelect = (item) => {
    let addItemToReasons = Boolean(!reasons.includes(item));
    if (addItemToReasons) {
      reasons += `\n-${item}`;
      onResultsChange('pvReason', reasons);
      setReasons(reasons);
      return;
    }
    reasons = reasons.replace(`\n-${item}`, '');
    onResultsChange('pvReason', reasons);
    setReasons(reasons);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <HeaderComponent
        icon={
          <IconButton aria-label='close' onClick={handleClose} disabled={saving}>
            <MdClose className='icon' />
          </IconButton>
        }
        title='Request changes'
      />
      <div className='form-shell flex'>
        <div className='layout vertical '>
          <div className='flex'>
            <div className='flex'>
              <FormControlLabel
                value={String()}
                control={
                  <Checkbox
                    onChange={(e) => {
                      handleSelect('Radiological Plans');
                    }}
                    checked={reasons.includes('Radiological Plans')}
                  />
                }
                label={'Radiological Plans'}
                labelPlacement='end'
              />
            </div>

            <div className='flex'>
              <FormControlLabel
                value={String()}
                control={
                  <Checkbox
                    onChange={() => {
                      handleSelect('Emergency Response Training');
                    }}
                    checked={reasons.includes('Emergency Response Training')}
                  />
                }
                label={'Emergency Response Training'}
                labelPlacement='end'
              />
            </div>

            <div className='flex'>
              <FormControlLabel
                value={String()}
                control={
                  <Checkbox
                    onChange={(e) => {
                      handleSelect('Protected Activation Staffing');
                    }}
                    checked={reasons.includes('Protected Activation Staffing')}
                  />
                }
                label={'Protected Activation Staffing'}
                labelPlacement='end'
              />
            </div>

            <div className='flex'>
              <FormControlLabel
                value={String()}
                control={
                  <Checkbox
                    onChange={(e) => {
                      handleSelect('Radiological Survey Instruments Check');
                    }}
                    checked={reasons.includes('Radiological Survey Instruments Check')}
                  />
                }
                label={'Radiological Survey Instruments Check'}
                labelPlacement='end'
              />
            </div>

            <div className='flex'>
              <FormControlLabel
                value={String()}
                control={
                  <Checkbox
                    onChange={(e) => {
                      handleSelect('Dosimetry Tests');
                    }}
                    checked={reasons.includes('Dosimetry Tests')}
                  />
                }
                label={'Dosimetry Tests'}
                labelPlacement='end'
              />
            </div>

            <div className='flex'>
              <div className='height-10' />
            </div>
          </div>
        </div>
        <TextField
          {...componentParams}
          label='comments'
          placeholder='Enter comments'
          multiline
          field='pvComments'
        />
        <div className='height-10' />
        <div className='layout horizontal'>
          {saving ? (
            <PrimaryButton className='flex margin-6' color='primary'>
              <CircularProgress color='neutral' />
            </PrimaryButton>
          ) : (
            <PrimaryButton className='flex margin-6' color='primary' onClick={handleSubmit}>
              Submit
            </PrimaryButton>
          )}
        </div>
      </div>
    </Dialog>
  );
}

RequestChangeDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  results: PropTypes.object,
  onSubmit: PropTypes.func,
  saving: PropTypes.bool,
};
