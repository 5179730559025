// noinspection JSValidateTypes

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Pagination, Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useStyles, { StyledTableCell, StyledTableRow } from './MuiTableGrid.styles';
import useFetchData from './hook/useFetchData';
import Loading from '../Loading';

const MuiTableGrid = ({ columns, ...props }) => {
  const { isFetching, data, count, pageCount } = useFetchData({ ...props });

  const classes = useStyles();

  useEffect(() => {
    if (count && count > 0) props.callBackCount && props.callBackCount(count);
  }, [count]);

  useEffect(() => {
    if (pageCount && pageCount > 0) props.callBackPageCount && props.callBackPageCount(pageCount);
  }, [pageCount]);

  useEffect(() => {
    props.callBackDataFetching && props.callBackDataFetching(isFetching);
  }, [isFetching]);

  const createSortHandler = (property) => (event) => {
    props.handleRequestSort(event, property);
  };

  if (isFetching || props.isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '80vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Loading />
      </Box>
    );

  return (
    <Paper sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <TableContainer sx={classes.tableContainer}>
        <Table sx={{ minWidth: 700, maxWidth: '1440px' }} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    sortDirection={props.orderBy === column.field ? props.order : false}
                    align={column.numeric ? 'right' : 'center'}
                    style={{ minWidth: `${column.minWidth} px` }}>
                    <TableSortLabel
                      active={props.orderBy === column.field}
                      direction={props.orderBy === column.field ? props.order : 'asc'}
                      onClick={createSortHandler(column.field)}>
                      {column.headerName}
                      {props.orderBy === column.field ? (
                        <Box component='span' sx={visuallyHidden}>
                          {props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              <StyledTableCell style={{ width: '20px' }}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0
              ? data.map((row, idx) => (
                  <StyledTableRow
                    key={row?.pvDataID || idx}
                    onClick={props.onRowClick ? () => props.onRowClick(row) : undefined}>
                    {columns &&
                      columns.map((column) => (
                        <StyledTableCell
                          component='th'
                          scope='row'
                          key={column.field}
                          align={column.numeric ? 'right' : 'left'}
                          style={{ minWidth: `${column.minWidth} px`, flex: 1 }}>
                          {column.renderCell ? column.renderCell(row) : row[column.field]}
                        </StyledTableCell>
                      ))}
                    <StyledTableCell>
                      <ChevronRightIcon fontSize={'small'} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={classes.pagination}
        shape='rounded'
        count={props.pageTotal}
        page={props.currentPage}
        onChange={(_e, num) => props.onPageChange(num)}
      />
    </Paper>
  );
};

MuiTableGrid.propTypes = {
  dataURL: PropTypes.string,
  filters: PropTypes.string,
  isLoading: PropTypes.bool,
  currentPage: PropTypes.number,
  limit: PropTypes.number,
  initialPageTotal: PropTypes.number,
  pageTotal: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowClick: PropTypes.func,
  callBackCount: PropTypes.func,
  callBackPageCount: PropTypes.func,
  callBackDataFetching: PropTypes.func,
  handleRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      numeric: PropTypes.bool,
      headerName: PropTypes.string,
      enabled: PropTypes.bool,
      minWidth: PropTypes.number,
      renderCell: PropTypes.func,
    }),
  ).isRequired,
};

export default MuiTableGrid;
