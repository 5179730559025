const Attendees = [
  'ESF 1',
  'ESF 2',
  'ESF 3',
  'ESF 4',
  'ESF 6',
  'ESF 7',
  'ESF 8',
  'ESF 9',
  'ESF 10',
  'ESF 11',
  'ESF 12',
  'ESF 13',
  'ESF 14',
  'ESF 15',
  'ESF 16',
  'ESF 17',
  'ESF 18',
  'ESF 19',
  'ESF 24',
  'SCEMD',
  'FEMA',
  'Guest',
];

export { Attendees };
