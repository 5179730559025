// Common things used internally to ICS204

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownMenuNotRequired,
  DropdownMenuNotRequiredMin,
  DropdownMenuRequired,
  DropdownMenuRequiredMin,
  MultiSelect,
  NormalTextFieldFunc,
  RequiredTextFieldFunc,
} from './FormComponents';

import { NormalTextField, PalmettoDatePicker } from '@zawarski/palmetto-ui-components';

export const TextField = (props) => {
  const { results, onResultsChange, validate = false } = props;
  const [value, setValue] = useState(results[props.field]);
  const cb = (value) => {
    setValue(value);
    onResultsChange(props.field, value);
  };
  useEffect(() => {
    setValue(results[props.field]);
  }, [results]); //  eslint-disable-line
  if (props.required) {
    return (
      <RequiredTextFieldFunc
        id={props.field}
        value={value}
        callback={cb}
        label={props.label}
        field={props.field}
        error={!value && validate}
        placeholder={props.placeholder}
        className='flex'
        custommargin='true'
        InputLabelProps={{
          shrink: true,
        }}
        {...props}
      />
    );
  } else {
    return (
      <NormalTextFieldFunc
        id={props.field}
        value={value}
        callback={cb}
        label={props.label}
        field={props.field}
        placeholder={props.placeholder}
        className='flex'
        custommargin='true'
        InputLabelProps={{
          shrink: true,
        }}
        {...props}
      />
    );
  }
};

TextField.propTypes = {
  required: PropTypes.bool,
  field: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export const Dropdown = (props) => {
  const { results, onResultsChange, validate = false } = props;
  const value = results[props.field] || '';
  const cb = (value) => {
    onResultsChange(props.field, value);
  };
  if (props.required) {
    return (
      <DropdownMenuRequired
        field={props.field}
        values={props.values?.sort()}
        callback={cb}
        name={props.label}
        id={props.field}
        placeholder={props.placeholder}
        value={value}
        error={validate && !value}
        {...props}
      />
    );
  } else {
    return (
      <DropdownMenuNotRequired
        field={props.field}
        values={props.values?.sort()}
        callback={cb}
        name={props.label}
        id={props.field}
        placeholder={props.placeholder}
        value={value}
        {...props}
      />
    );
  }
};

Dropdown.propTypes = {
  field: PropTypes.string,
  values: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  results: PropTypes.object,
};

Dropdown.defaultProps = {
  results: {},
};

export const DropdownMin = (props) => {
  const { results, onResultsChange, validate = false } = props;
  const value = results[props.field] || '';
  const cb = (value) => {
    onResultsChange(props.field, value);
  };
  if (props.required) {
    return (
      <DropdownMenuRequiredMin
        field={props.field}
        values={props.values?.sort()}
        callback={cb}
        name={props.label}
        id={props.field}
        placeholder={props.placeholder}
        value={value}
        error={validate && !value}
        {...props}
      />
    );
  } else {
    return (
      <DropdownMenuNotRequiredMin
        field={props.field}
        values={props.values?.sort()}
        callback={cb}
        name={props.label}
        id={props.field}
        placeholder={props.placeholder}
        value={value}
        error={validate && !value}
        {...props}
      />
    );
  }
};

DropdownMin.propTypes = {
  field: PropTypes.string,
  values: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  results: PropTypes.object,
  required: PropTypes.bool,
};

DropdownMin.defaultProps = {
  results: {},
};

export const Field = (props) => {
  const { results } = props;
  return (
    <NormalTextField
      id={props.field}
      value={props.value || (results && results[props.field]) || ''}
      label={props.label}
      field={props.field}
      className='flex'
      custommargin='true'
      InputProps={{
        readOnly: true,
        ...props.InputProps,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
};

Field.propTypes = {
  field: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  results: PropTypes.object,
  InputProps: PropTypes.object,
};

export const DatePicker = (props) => {
  const [date, setDate] = useState(props.value || ' ');
  const { callback, value, ...other } = props;
  const [datePickerRef, setDatePickerRef] = useState({});

  const onChange = (event, value) => {
    setDate(value);
    props.callback && props.callback(value);
  };

  const DateRef = (ref) => {
    setDatePickerRef(ref);
  };

  return (
    <>
      <PalmettoDatePicker
        {...other}
        value={date}
        refFunc={DateRef}
        onChange={onChange}
        onClose={datePickerRef.closeDatePicker}
      />
    </>
  );
};

// Fixes reference issues leading to strange behavior in personnel
export function dereferenceArr(arr) {
  const toRet = [];
  for (let entry of arr) {
    const newRef = {};
    Object.assign(newRef, entry);
    toRet.push(newRef);
  }
  return toRet;
}

export function dereference(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const MSelect = (props) => {
  return (
    <MultiSelect
      name='County'
      callback={props.handleSelect}
      values={props.values}
      options={props.options}
      {...props}
    />
  );
};

MSelect.propTypes = {
  values: PropTypes.array,
  options: PropTypes.array,
  name: PropTypes.string,
  handleSelect: PropTypes.func,
};
