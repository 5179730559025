import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { GetPageNumber } from '../utils';

const useFetchData = (props) => {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [count, setCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [error, setError] = useState(null);

  const token =
    sessionStorage.getItem('userAuthCreds') &&
    JSON.parse(sessionStorage.getItem('userAuthCreds')).id;
  const fetchData = (URL, isInitialPage) => {
    setIsFetching(true);
    setTimeout(async () => {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };

      try {
        const offsetCount = (props.currentPage - 1) * props.limit;
        const newOffSet =
          offsetCount < props.initialPageTotal ? offsetCount : props.currentPage - 1;
        const order = `orders[${props.orderBy}]`;
        let tempURL = URL;
        if (tempURL.includes('?') && props.orderBy) {
          tempURL += `&${order}=${props.order}`;
        }
        if (!tempURL.includes('?') && props.orderBy) {
          tempURL += `?${order}=${props.order}`;
        }

        const response = await axios({
          method: 'GET',
          url: tempURL,
          responseType: 'json',
          headers: headers,
          params: {
            access_token: token,
            limit: props.limit,
            offset: isInitialPage ? 0 : newOffSet,
          },
          validateStatus: (status) => {
            return status < 500;
          },
        })
          .catch((e) => {
            return {
              status: e.response?.status,
              data: e.response?.data,
            };
          })
          .then((response) => ({
            status: response.status,
            data: response.data,
          }));

        let initialTotalCount = 0;
        if (isInitialPage) {
          initialTotalCount = response.data?.count;
          setCount(initialTotalCount);
          const pageCount =
            initialTotalCount > props.limit ? GetPageNumber(initialTotalCount, props.limit) : 1;
          setPageCount(pageCount);
        }

        setData(response.data?.dataset || []);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
        setError(error.toString());
        setIsFetching(false);
      }
    }, 1000);
  };

  useEffect(() => {
    if (!props.dataURL) return;
    if (props.filters) {
      if (props.initialPageTotal === 0) {
        // *: GET INITIAL DATA
        fetchData(`${props.dataURL}${props.filters}`, true);
      } else {
        // *: GET PAGINATED DATA
        fetchData(`${props.dataURL}${props.filters}`, false);
      }
    } else {
      if (props.initialPageTotal === 0) {
        fetchData(props.dataURL, true);
      } else {
        fetchData(props.dataURL, false);
      }
    }
  }, [props.dataURL, props.filters, props.currentPage, props.order, props.orderBy]);

  return { data, isFetching, count, pageCount, error };
};

useFetchData.propTypes = {
  dataURL: PropTypes.string.isRequired,
  filters: PropTypes.string,
  currentPage: PropTypes.number,
  limit: PropTypes.number,
  initialPageTotal: PropTypes.number,
  pageTotal: PropTypes.number,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default useFetchData;
