//import { withPalmettoAuth } from "./components/auth/PalmettoAuth";
//import App from "./App";
//import { datadogLogs } from "@datadog/browser-logs";
//datadogLogs.init({
//  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
//  datacenter: "us",
//  env: process.env.REACT_APP_DATADOG_STAGING_ENV,
//  forwardErrorsToLogs: false,
//  sampleRate: 100,
//});

//export default withPalmettoAuth(App);

import React from 'react';
import PalmettoAuthV2 from './components/auth/PalmettoAuthV2';
import App from './App';
import { datadogLogs } from '@datadog/browser-logs';

const AppShell = () => {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    datacenter: 'us',
    env: process.env.REACT_APP_DATADOG_STAGING_ENV,
    forwardErrorsToLogs: false,
    sampleRate: 100,
  });
  return (
    <PalmettoAuthV2>
      <App />
    </PalmettoAuthV2>
  );
};

export default AppShell;
