import React from 'react';
import {
  MdComment as CommentIcon,
  MdInfo as InfoIcon,
  MdSimCardDownload as DownloadIcon,
} from 'react-icons/md';
import { PiSealCheckFill as CheckIcon } from 'react-icons/pi';

const TimeLineType = {
  info: 'info',
  comments: 'comments',
  completed: 'completed',
  download: 'download',
};

const TimeLineTypeIcon = {
  info: <InfoIcon color='#2961FD' className='icon' />,
  comments: <CommentIcon color='#999999' className='icon' />,
  completed: <CheckIcon color='#6EAA2C' className='icon' />,
  download: <DownloadIcon color='#1C43B1' className='icon' />,
};

export { TimeLineType, TimeLineTypeIcon };
