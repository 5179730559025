import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApplicationSpinner } from '@zawarski/palmetto-ui-components';
import { MdErrorOutline, MdInfoOutline, MdCheckCircle, MdAccessTime } from 'react-icons/md';

export const Loading = (props) => {
  return (
    <div className='layout vertical vertical-center full-height'>
      <ApplicationSpinner />
      <div className='height-20'></div>
      <span style={{ padding: '0 16px' }} className='text-center title-big opacity-54'>
        {props.message}
      </span>
    </div>
  );
};

Loading.propTypes = {
  message: PropTypes.string,
};

export const Error = (props) => {
  return (
    <div className='layout vertical vertical-center full-height'>
      <MdErrorOutline
        style={{ color: 'rgba(0, 0, 0, 0.8)', width: '100%', heigth: '100%' }}
        size={52}
      />
      <div className='height-20'></div>
      <span style={{ padding: '0 16px' }} className='text-center title-big opacity-54'>
        {props.message}
      </span>
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string,
};

export const Info = (props) => {
  return (
    <div className='layout vertical vertical-center full-height'>
      <span>
        <MdInfoOutline
          style={{ color: 'rgba(0, 0, 0, 0.8)', width: '100%', heigth: '100%' }}
          size={52}
        />
      </span>
      <div className='height-20'></div>
      <span style={{ padding: '0 16px' }} className='text-center title-big opacity-54'>
        {props.message}
      </span>
    </div>
  );
};

Info.propTypes = {
  message: PropTypes.string,
};

export const Message = (props) => {
  return <span className='opacity-54 margin-6'>{props.message}</span>;
};

Message.propTypes = {
  message: PropTypes.string,
};

export const Approved = (props) => {
  return (
    <div className='layout vertical vertical-center full-height'>
      <span style={{ heigth: '40px', width: '40px' }}>
        <MdCheckCircle className='icon' style={{ color: '#238f55' }} />
      </span>
      <div className='height-20'></div>
      <span style={{ padding: '0 16px' }} className='text-center title-big opacity-54'>
        {props.message}
      </span>
    </div>
  );
};

export const Submitted = (props) => {
  return (
    <div className='layout vertical vertical-center full-height'>
      <span>
        <MdAccessTime
          style={{ color: 'rgba(0, 0, 0, 0.8)', width: '100%', heigth: '100%' }}
          size={52}
        />
      </span>
      <div className='height-20'></div>
      <span style={{ padding: '0 16px' }} className='text-center title-big opacity-54'>
        {props.message}
      </span>
    </div>
  );
};
