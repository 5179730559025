import moment from 'moment';

export function ValidateEmailAddress(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function ValidatePhoneNumber(phone) {
  const re = /^\d{10}$/;
  return re.test(phone);
}

export const convertToTitleCase = (str) => {
  str = str.toLowerCase();
  return str.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
};

export const FormatDate = (date, format = 'YYYY-MM-DD hh:mm A') => {
  return (date && moment(date).utc().format(format)) || '';
};

export function ValidateDate(date) {
  const formats = [moment.ISO_8601, 'YYYY-MM-DD', 'MM-DD-YYYY', 'MM/DD/YYYY'];
  return moment(date, formats, true).isValid();
}

export function FormatDateOffSetDatePicker(pvEvalDate) {
  if (!pvEvalDate) return '';
  // Parse the date string with the specific format and timezone
  const fixedDate = moment.tz(pvEvalDate, 'YYYY-MM-DD', 'America/New_York');
  // Format and display the fixed date
  return fixedDate.format('YYYY-MM-DD');
}

export function FormatDateOffSetCard(pvEvalDate) {
  if (!pvEvalDate) return '';
  // Parse the date string with the specific format and timezone
  return moment(pvEvalDate).utc().format('MM/DD/YYYY');
}

export function FormatYearOnly(pvDate) {
  if (!pvDate) return '';
  // Parse the date string with the specific format and timezone
  const fixedDate = moment.tz(pvDate, 'MM/DD/YYYY', 'America/New_York');
  // Format and display the fixed date
  return fixedDate.format('YYYY');
}

export function IsDateChanged(date) {
  const formats = ['YYYY-MM-DD'];
  return moment(date, formats, true).isValid();
}

export function FullDateFormat(val) {
  if (!val) return null;
  return moment(val).utc().format('dddd, MMMM Do, YYYY');
}

export function findKeyIndexArrObj(arr, key) {
  if (Array.isArray(arr) && arr.length) {
    let indexValue = -1;
    for (let i = 0; i < arr.length; i++) {
      const value = arr[i];
      if (typeof value === 'object') {
        const objKeys = Object.keys(value);
        const checkCode = objKeys.includes(key);
        if (checkCode) {
          indexValue = i;
        }
      }
    }
    return indexValue;
  } else {
    return -1;
  }
}
