import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useStyles from './ScrollTabs.styles';
import PropTypes from 'prop-types';

const ScrollTabs = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme, tabsClasses);
  const [selectedIndex, setSelectedIndex] = useState(Number(props.selectedIndex));

  const handleChange = (idx) => {
    setSelectedIndex(() => idx);
    const organizationID = props.options[idx].id;
    props.setOrgID(() => organizationID);
    props.onChange(props.options[idx].id);
  };

  return (
    <Tabs
      aria-label='visible arrows tabs example'
      sx={classes.tabs}
      value={selectedIndex}
      variant='scrollable'
      scrollButtons
      indicatorColor='transparent'>
      {props.options && props.options.length
        ? props.options.map((item, idx) => {
            return <Tab key={item.id} label={item.title} onClick={() => handleChange(idx)} />;
          })
        : null}
    </Tabs>
  );
};

ScrollTabs.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  selectedIndex: PropTypes.number,
  selectedItem: PropTypes.string,
  setOrgID: PropTypes.func,
  onChange: PropTypes.func,
};

ScrollTabs.defaultProps = {
  options: [],
  selectedIndex: 0,
  selectedItem: '',
  onChange: () => {},
};

export default ScrollTabs;
