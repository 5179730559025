import { createTheme } from '@mui/material/styles';
import lightPalette from './palettes/lightPallete';
import typography from './typography';

const theme = createTheme({
  palette: lightPalette,
  typography,
});

export default theme;
