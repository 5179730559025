/**
 * Contains actions related to printing and generating documents
 */
import moment from 'moment';
import { apiClient } from '../../common/axios/axios_instance';

const ENDPOINT = process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;

const REQUEST_CONFIG = {
  responseType: 'arraybuffer',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
};

/**
 * Generates and Downloads a document from the serverless Documents Endpoint
 * @param {Object} payload parameters for the document
 * @param {Function} callback something to call when the document is downloaded
 */
export function downloadDocument(payload, callback) {
  return async function (dispatch, getState) {
    let resp = null;

    try {
      if (payload.endpoint) {
        resp = await apiClient.get(payload.endpoint, REQUEST_CONFIG);
      } else {
        if (payload.body) {
          resp = await apiClient.post(ENDPOINT + payload.url, payload.body, REQUEST_CONFIG);
        } else {
          resp = await apiClient.get(ENDPOINT + payload.url, REQUEST_CONFIG);
        }
      }

      let type = resp.headers['content-type'] || 'application/vnd.ms-excel';
      let filename = 'document';
      if (payload.filename) {
        filename = payload.filename;
      } else if (resp.headers['content-disposition']) {
        filename = getFilenameFromHeader(resp.headers['content-disposition']);
      }
      let blob = new Blob([resp.data], { type: type });
      let url = URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error('Failed to get Document:', err);
      if (callback) {
        callback(err);
      }
    }
  };
}

// Gets the filename from the content disposition header
function getFilenameFromHeader(contentDisp) {
  // attachment;  filename="ICS_203-Alien Invasion1-0112211159.docx"
  const beginning = 'filename=';
  let toRet = contentDisp
    .substring(contentDisp.indexOf(beginning) + beginning.length)
    .replaceAll('"', '')
    .replaceAll("'", '');
  return toRet;
}

export function printTable(type, payload, callback) {
  return async function (dispatch, getState) {
    let postPayload = payload;
    let url = process.env.REACT_APP_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;
    apiClient
      .post(url + '/documents/' + type + '/print', postPayload, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', type + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
    if (callback) {
      callback();
    }
  };
}

function ExcelSheetConfiguration(ExtraModifications) {
  const BaseRequestConfiguration = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  };

  if (
    !ExtraModifications ||
    !Object.keys(ExtraModifications) ||
    !Object.keys(ExtraModifications).length
  ) {
    return BaseRequestConfiguration;
  }

  Object.assign(BaseRequestConfiguration, ExtraModifications);

  return BaseRequestConfiguration;
}

export async function HandleDownloadingExcelFile(RequestUrl, Filename) {
  try {
    const DocumentServiceResponse = await apiClient.get(RequestUrl, ExcelSheetConfiguration({}));
    const AnchorTag = document.createElement('a');

    // Define the url for the document
    AnchorTag.href = window.URL.createObjectURL(new Blob([DocumentServiceResponse.data]));

    // Defining the filename
    const ExcelFileName = `${Filename}-${moment().format('L')}.xlsx`;
    AnchorTag.setAttribute('download', ExcelFileName);

    // Attach link to the body of the body of our public html file
    document.body.appendChild(AnchorTag);

    // Click the link
    AnchorTag.click();

    // Remove the element
    AnchorTag.remove();
  } catch (error) {
    console.error(error);
  }
}

export function printALOCReport(payload, callback) {
  return async function () {
    const url = ENDPOINT + '/documents/rep/aloc';
    apiClient
      .post(url, payload)
      .then((response) => {
        if (response.data && response.data.url) {
          const anchor = document.createElement('a');
          anchor.download = response.data.name;
          anchor.href = response.data.url;
          anchor.click();
          callback && callback();
        }
      })
      .catch((error) => {
        console.log(error);
        callback && callback();
      });
  };
}
