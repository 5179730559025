// noinspection JSUnusedLocalSymbols

/**
 * File Uploader that's compatible with old-stack Palmetto apps
 */

import React, { useEffect, useState } from 'react';
// import { apiClient } from '../../redux/axios/axios_instance';
import apiClient from 'axios';
import { makeStyles } from '@mui/styles';
import { MdClear, MdFileUpload } from 'react-icons/md';
import IconButton from '@mui/material/IconButton';
import { CardComponentDownloadCard, PrimaryButton } from '@zawarski/palmetto-ui-components';

import '../../styles/common.css';
import '@zawarski/palmetto-ui-components/dist/styles/common.css';
import '@zawarski/palmetto-ui-components/dist/styles/iron-flex-layout.css';
import '@zawarski/palmetto-ui-components/dist/styles/iron-flex-layout-classes.css';

/**
 * Takes care of adding and deleting files from the old file endpoints
 * @param {Array} filesToAdd Array of files to add to palmetto
 * @param {Array} filesToDelete Array of files to delete
 * @param {String} uuid pvGlobalGroupID to associate files with
 * @returns Number of files, which should
 */
export const addOrDeleteFilesFunc = async (filesToAdd = [], filesToDelete = [], uuid) => {
  let calls = [];
  for (let i = 0; i < filesToAdd.length; i++) {
    let url = `${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/files/updateID?access_token=${
      sessionStorage.getItem('userAuthCreds') &&
      JSON.parse(sessionStorage.getItem('userAuthCreds')).id
    }`;
    let obj = {
      pvDataID: filesToAdd[i].pvDataID,
      pvGlobalGroupID: uuid,
    };
    calls.push(
      apiClient.put(url, obj, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  }

  for (let i = 0; i < filesToDelete.length; i++) {
    let url = `${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/files/delete?access_token=${
      sessionStorage.getItem('userAuthCreds') &&
      JSON.parse(sessionStorage.getItem('userAuthCreds')).id
    }`;
    let obj = {
      globalid: filesToDelete[i].pvGlobalID,
    };
    calls.push(
      apiClient.put(url, obj, {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    );
  }

  await Promise.all(calls);

  return filesToAdd.length - filesToDelete.length;
};

const useStyles = makeStyles((theme) => {
  return {
    uploadContainer: {
      borderRadius: '4px',
      border: 'dotted 2px var(--pcl-primary-background-color)',
      backgroundColor: 'rgba(41, 97, 253, 0.05)',
      height: '199px',
    },
    uploadIcon: {
      width: '72px',
      height: '72px',
      opacity: '0.87',
    },
    text: {
      opacity: '0.87',
      fontSize: '20px',
      fontWeight: '500',
    },
    primaryProgress: {
      height: '8px',
      WebkitTransformOrigin: 'left center',
      transformOrigin: 'left center',
      WebkitTransform: 'scaleX(0)',
      transform: 'scaleX(0)',
      willChange: 'transform',
      borderRadius: '2px',
      backgroundColor: '#3063A5',
    },
    progressContainer: {
      height: '8px',
      borderRadius: '2px',
      backgroundColor: '#d8d8d8',
      position: 'relative',
      width: '280px',
    },
    inputTypeFile: {
      visibility: 'hidden',
      width: '0',
      height: '0',
    },
    uploadCardMargin: {
      margin: '6px',
    },
  };
});

function PalmettoFileWrapper(props) {
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesQueued, setFilesQueued] = useState([]);
  const [filesDeleted, setFilesDeleted] = useState([]);
  const [fetching, setFetching] = useState(false);
  // const [uploadingProgressText, setUploadingProgressText] = useState('');

  let uploadInput = React.useRef();
  let primaryProgressRef = React.useRef();

  const classes = useStyles();

  const uploadButtonClick = () => {
    uploadInput && uploadInput.click && uploadInput.click();
  };

  const upload = async (file, url, onUploadProgress) => {
    let formData = new FormData();

    formData.append('file', file);

    let fileUploadResponse = await apiClient.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        uuid: props.uuid,
        userid: props.user.id,
        username: props.user.username,
      },
      onUploadProgress,
    });

    return fileUploadResponse.data || {};
  };

  const uploadFiles = async (files, payload, callback) => {
    let size = 0;

    // Calculate size of files uploaded
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      size += file.size;
    }

    let uploadedFiles = [];
    let sizeUploaded = 0;

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let url = `${
        process.env.REACT_APP_PALMETTO_ENDPOINT
      }/api/files/uploadWithoutID?access_token=${
        sessionStorage.getItem('userAuthCreds') &&
        JSON.parse(sessionStorage.getItem('userAuthCreds')).id
      }&filter=${JSON.stringify({ where: { pvGlobalGroupID: payload.uuid } })}`;
      // eslint-disable-next-line no-loop-func
      let uploadedFile = await upload(file, url, (event) => {
        // let progressInPercent = parseInt(((sizeUploaded + event.loaded) *100) / size);
        primaryProgressRef &&
          primaryProgressRef.current &&
          (primaryProgressRef.current.style.transform =
            'scaleX(' + (sizeUploaded + event.loaded) / size + ')'); // eslint-disable-line
      });
      sizeUploaded += file.size;
      uploadedFiles.push(uploadedFile);
    }

    return uploadedFiles;
  };

  const onChange = async (e) => {
    setUploading(true);

    let payload = {
      userId: props.user.id,
      uuid: props.uuid,
      username: props.user.username,
    };

    let tempUploadedFiles = await uploadFiles(e.target.files, payload);

    let arr = filesQueued;
    arr = arr.concat(tempUploadedFiles);
    setFilesQueued(JSON.parse(JSON.stringify(arr)));

    let arr1 = uploadedFiles;
    arr1 = arr1.concat(tempUploadedFiles);

    setUploadedFiles(JSON.parse(JSON.stringify(arr1)));

    setUploading(false);
    props.filesQueuedCallback && props.filesQueuedCallback(arr);
  };

  const addOrDeleteFiles = async (filesToAdd = [], filesToDelete = [], uuid) => {
    let calls = [];
    for (let i = 0; i < filesToAdd.length; i++) {
      let url = `${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/files/updateID`;
      let obj = {
        pvDataID: filesToAdd[i].pvDataID,
        pvGlobalGroupID: uuid,
      };
      calls.push(
        apiClient.put(url, obj, {
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      );
    }

    for (let i = 0; i < filesToDelete.length; i++) {
      let url = `${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/files/delete`;
      calls.push(
        apiClient.put(url, 'globalid=' + filesToDelete[i].pvGlobalID, {
          headers: {
            Accept: 'application/json',
          },
        }),
      );
    }

    await Promise.all(calls);

    return filesToAdd.length - filesToDelete.length;
  };

  const deleteFile = (event, item) => {
    let arr = uploadedFiles;
    let fileToDelete = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].pvDataID === item.pvDataID) {
        fileToDelete.push(JSON.parse(JSON.stringify(arr[i])));
        arr.splice(i, 1);
      }
    }
    setUploadedFiles(JSON.parse(JSON.stringify(arr)));
    let arr1 = filesDeleted;
    arr1 = arr1.concat(fileToDelete);
    setFilesDeleted(JSON.parse(JSON.stringify(arr1)));
    props.filesDeletedCallback && props.filesDeletedCallback(arr1);
  };

  const getProperKeyName = (name) => {
    if (name && name.indexOf('_DUMMYSEARCHSTRING_') >= 0) {
      return name.substring(name.indexOf('_DUMMYSEARCHSTRING_') + 19);
    }
    return name;
  };

  /**
   * Returns the Size of the file from a bytes value
   * @param {Number} bytes Number of Bytes
   */
  const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    // eslint-disable-next-line
    if (bytes === 0) return '0 Byte';
    const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  // const isImageType = (type) => {
  //     return type && type.indexOf("image") >= 0;
  // }

  function _spliceDate(name) {
    //splice out the dummystring if it exists (If it doesn't it's an old file)
    if (name && name.indexOf('_DUMMYSEARCHSTRING_') >= 0) {
      return name.substring(name.indexOf('_DUMMYSEARCHSTRING_') + 19);
    }
    return name;
  }

  const downloadFile = (item) => {
    const payload = item;
    const a = document.createElement('a');
    a.href =
      (process.env.REACT_APP_PALMETTO_ENDPOINT || window.origin) +
      payload.cbrnDataFileURIID +
      '?access_token=' +
      window.palmettoToken.id;
    a.target = '_blank';
    a.download = _spliceDate(payload.cbrnDataFileURIID);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);
      if (props.uuid) {
        let url = `${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/files?access_token=${
          sessionStorage.getItem('userAuthCreds') &&
          JSON.parse(sessionStorage.getItem('userAuthCreds')).id
        }&filter=${JSON.stringify({ where: { pvGlobalGroupID: props.uuid } })}`;
        const filesRes = await apiClient.get(url);
        return Array.isArray(filesRes.data) ? filesRes.data : [];
      }
    };

    // props.addOrDeleteFiles && props.addOrDeleteFiles(addOrDeleteFiles);

    fetchData()
      .then((arr) => {
        setUploadedFiles(JSON.parse(JSON.stringify(arr)));
        // Set the initial number of uploaded files
        props.addOrDeleteFiles && props.addOrDeleteFiles(addOrDeleteFiles, arr.length);
      })
      .finally(() => {
        setFetching(false);
      });

    return function cleanup() {};
  }, [props.uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  const getImage = (item) => {
    let img = 'https://www.palmettoeoc.com';

    if (!item.pvMIMEType) return img + '/images/icons/mime/unknownextension.png';

    switch (item.pvMIMEType.toLowerCase()) {
      case 'image/png':
        img += '/images/icons/mime/png.png';
        break;
      case 'image/jpeg':
        img += '/images/icons/mime/jpeg.png';
        break;
      case 'image/gif':
        img += '/images/icons/mime/gif.png';
        break;
      case 'application/vnd.google-earth.kmz':
      case 'application/vnd.google-earth.kml+xml':
        img += '/images/icons/mime/kml.png';
        break;
      case 'text/html':
        img += '/images/icons/mime/html.png';
        break;
      case 'application/x-mswebsite':
        img += '/images/icons/mime/web.png';
        break;
      case 'image/svg+xml':
        img += '/images/icons/mime/svg.png';
        break;
      case 'text/plain':
        img += '/images/icons/mime/txt.png';
        break;
      case 'application/pdf':
        img += '/images/icons/mime/pdf.png';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        img += '/images/icons/mime/xls.png';
        break;
      case 'application/x-zip-compressed':
        img += '/images/icons/mime/zip.png';
        break;
      default:
        img += '/images/icons/mime/unknownextension.png';
        break;
    }

    return img;

    // if (uri && uri.indexOf("%2F") >= 0) {
    //     var part1 = uri.substring(0, uri.indexOf("%2F") + 3);
    //     var part2 = uri.substring(uri.indexOf("%2F") + 3);
    //     part2 = encodeURIComponent(part2);
    //     return window.PALMETTO_CONFIGURATION.APP_API_URL + part1 + part2;
    // }
    // return  window.PALMETTO_CONFIGURATION.APP_API_URL + uri;
  };

  return (
    <div className='layout vertical'>
      {props.readonly ? (
        <div className='layout horizontal wrap'>
          {!fetching &&
            uploadedFiles &&
            uploadedFiles.map((item) => {
              if (!item.pvVoid) {
                return (
                  <div
                    onClick={() => downloadFile(item)}
                    className={`cursor-pointer ${classes.uploadCardMargin}`}
                    style={{ cursor: 'pointer' }}
                    key={item.pvDataID}>
                    <CardComponentDownloadCard
                      className='set-width'
                      icon={<img style={{ width: 40, height: 40 }} alt=' ' src={getImage(item)} />}
                      title={
                        <div className='layout vertical'>
                          <span className='truncate'>
                            {getProperKeyName(item.cbrnDataFileName)}
                          </span>
                          <span className='opacity-54'>{bytesToSize(item.cbrnDataFileSize)}</span>
                        </div>
                      }
                    />
                  </div>
                );
              } else {
                return '';
              }
            })}
        </div>
      ) : (
        <>
          <div className='layout horizontal wrap'>
            {!fetching &&
              uploadedFiles &&
              uploadedFiles.map((item) => {
                if (!item.pvVoid) {
                  return (
                    <div className={`${classes.uploadCardMargin}`} key={item.pvDataID}>
                      <div style={{ width: '100%' }}>
                        <CardComponentDownloadCard
                          // className="set-width"
                          // title={<span className="truncate">{getProperKeyName(item.cbrnDataFileName)}</span>}
                          // title2={<span className="opacity-54">{bytesToSize(item.cbrnDataFileSize)}</span>}
                          className='set-width'
                          icon={
                            <img style={{ width: 40, height: 40 }} alt=' ' src={getImage(item)} />
                          }
                          title={
                            <div className='layout vertical'>
                              <span className='truncate'>
                                {getProperKeyName(item.cbrnDataFileName)}
                              </span>
                              <span className='opacity-54'>
                                {bytesToSize(item.cbrnDataFileSize)}
                              </span>
                            </div>
                          }
                          suffix={
                            <IconButton
                              aria-label='Delete'
                              aria-haspopup='true'
                              onClick={(e) => deleteFile(e, item)}>
                              <MdClear className='icon-2' />
                            </IconButton>
                          }
                        />
                      </div>
                    </div>
                  );
                } else {
                  return '';
                }
              })}
          </div>

          <div className='height-10'></div>

          <div
            style={{ height: '220px' }}
            className={`layout vertical center vertical-center full-height ${classes.uploadContainer}`}>
            <input
              ref={(input) => {
                uploadInput = input;
              }}
              type='file'
              className={`${classes.inputTypeFile}`}
              multiple
              onChange={(e) => onChange(e)}
            />

            <div className='height-10'></div>
            <MdFileUpload className={`${classes.uploadIcon}`} />
            <div className='height-10'></div>
            <span className={`${classes.text}`}>Upload files</span>
            <div className='height-10'></div>
            <div className='layout horizontal'>
              {uploading ? (
                <div className={`${classes.progressContainer}`}>
                  <div ref={primaryProgressRef} className={`${classes.primaryProgress}`}></div>
                  {/* <span> {uploadingProgressText}</span> */}
                </div>
              ) : (
                <PrimaryButton onClick={uploadButtonClick} style={{ width: '256px' }}>
                  Add file
                </PrimaryButton>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PalmettoFileWrapper;
