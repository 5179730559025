import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typo from '../Typo';
import { Box } from '@mui/material';
import useStyles from './DocumentWrapper.styles';
import { DropFileZone, FileContainer } from '../index';
import { bytesToSize, getProperKeyNameDummySearchString } from '../../../common/FileUploadAPI';
import { useDispatch } from 'react-redux';
import { downloadDocument } from '../../../redux/actions/documents';
import useUploadDocForm from '../../hooks/useUploadDocForm';

const DocumentWrapper = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles(props.readOnly);

  const {
    downloadForm,
    uploadedDocuments: uploadedFiles,
    inProgress,
    setUploadedDocuments,
  } = useUploadDocForm();
  console.log('🚀 DocumentWrapper uploadedFiles: ', uploadedFiles);
  useEffect(() => {
    const fetchData = async (url) => {
      const config = {
        headers: { 'Content-Type': 'application/json' },
      };
      downloadForm(url, config).catch((err) => {
        console.error('🚀 Documents fetchData err: ', err);
      });
    };
    if (props.uuid) {
      let url = `${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/files?access_token=${
        sessionStorage.getItem('userAuthCreds') &&
        JSON.parse(sessionStorage.getItem('userAuthCreds')).id
      }&filter=${JSON.stringify({ where: { pvGlobalGroupID: props.uuid } })}`;
      // noinspection JSIgnoredPromiseFromCall
      fetchData(url);
    }
  }, [props.uuid]);

  function _spliceDate(name) {
    //splice out the _DUMMYSEARCHSTRING_ if it exists and if empty it's an old file
    if (name && name.indexOf('_DUMMYSEARCHSTRING_') >= 0) {
      return name.substring(name.indexOf('_DUMMYSEARCHSTRING_') + 19);
    }
    return name;
  }

  const downloadFile = (item) => {
    const payload = item;
    const fileName = _spliceDate(payload.cbrnDataFileURIID);
    const url =
      (process.env.REACT_APP_PALMETTO_ENDPOINT || window.origin) +
      payload.cbrnDataFileURIID +
      '?access_token=' +
      window.palmettoToken.id;
    // noinspection JSIgnoredPromiseFromCall
    dispatch(
      downloadDocument({ endpoint: url, filename: fileName }, () => {
        console.log('Downloaded');
      }),
    );
  };

  const uploadedDropZoneFiles = (arrFiles) => {
    if (Array.isArray(arrFiles) && arrFiles.length) {
      setUploadedDocuments((prevFiles) => [...prevFiles, ...arrFiles]);
    }
  };

  const deletedDropZoneFiles = (arrFiles) => {
    if (Array.isArray(arrFiles) && arrFiles.length) {
      for (let i = 0; i < arrFiles.length; i++) {
        const fileToDelete = arrFiles[i];
        const findIndex = uploadedFiles.findIndex(
          (file) => file.pvDataID === fileToDelete.pvDataID,
        );
        if (findIndex > -1) {
          const filteredFiles = [...uploadedFiles];
          filteredFiles.splice(findIndex, 1);
          setUploadedDocuments(filteredFiles);
        }
      }
    }
  };

  return (
    <Box sx={classes.container} hidden={inProgress}>
      <Typo sx={classes.title}>
        {uploadedFiles.length
          ? props.title
          : props.readOnly
          ? props.defaultReadOnlyTitle
          : props.defaultHeaderTitle}
      </Typo>
      {props.readOnly ? (
        <Box sx={classes.content}>
          {!inProgress && uploadedFiles && uploadedFiles.length ? (
            uploadedFiles.map((item, idx) => {
              if (!item.pvVoid) {
                return (
                  <FileContainer
                    key={idx}
                    item={item}
                    name={getProperKeyNameDummySearchString(item.cbrnDataFileName)}
                    bytes={bytesToSize(item.cbrnDataFileSize)}
                    downloadFile={downloadFile}
                    readOnly={true}
                  />
                );
              } else {
                return <></>;
              }
            })
          ) : (
            <Box sx={classes.empty}>
              <Typo sx={classes.emptyText}>No document added</Typo>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={classes.content}>
          <DropFileZone
            uuid={props.uuid}
            user={props.user}
            filesQueuedCallback={props.filesQueuedCallback}
            filesDeletedCallback={props.filesDeletedCallback}
            filesUploadedCallback={uploadedDropZoneFiles}
            filesDeletedCB={deletedDropZoneFiles}
            pvDataID={props.pvDataID}
          />
        </Box>
      )}
    </Box>
  );
};

DocumentWrapper.prototype = {
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  defaultHeaderTitle: PropTypes.string,
  defaultReadOnlyTitle: PropTypes.string,
  uuid: PropTypes.string,
  user: PropTypes.object,
  pvDataID: PropTypes.number,
  filesQueuedCallback: PropTypes.func,
  filesDeletedCallback: PropTypes.func,
};

DocumentWrapper.defaultProps = {
  readOnly: false,
  title: '',
  defaultHeaderTitle: 'File Upload',
  defaultReadOnlyTitleTitle: 'Please upload your training roster and completed checklist',
};

export default DocumentWrapper;
