const useStyles = (isMobile) => ({
  file: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    pr: '36px',
    gap: '12px',
    borderRadius: '4px',
    border: '1px solid #E0E0E0',
    background: '#FFF',
    cursor: 'pointer',
    position: 'relative',
    minWidth: isMobile ? 'unset' : '320px',
  },
  fileNameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  title: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#252525',
    textTransform: 'uppercase',
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#808080',
  },
  removeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  borderSelected: {
    border: '1px solid #2961FD',
  },
});

export default useStyles;
