/**
 * Contains all possible types for actions. This is also provides a single source of truth for all possible actions
 */

// Maps Action names to the variables that they change for the root reducer
export const ALL_TYPES = {
  set_application_loading_state: 'appLoading', // App Loading State
  set_groups: 'groups', // Palmetto groups
  set_login_state: 'loginState', // true if user is logged in
  set_app_type: 'appType', // Sets the App Type
  set_user_settings: 'userSettings', // true if user is logged in
  set_selected_group: 'selectedGroup', // User Selected Group Object
  set_health: 'health', // true if the connection is still active and healthy
  set_palmetto_user_account: 'palmettoUserAccount', // basic user information from palmetto
  set_user: 'user', // Sets the USer Account Info
  set_incidents: 'incidents', // All the incidents available to the user,
  set_app_permissions: 'appPermissions', // User's app permissions
  set_is_current_user_subgroup_user: 'isCurrentUserSubgroupUser', // tells the frontend whether the user is a subgroup user or not
  set_current_user_subgroup: 'currentUserSubgroup', // subgroup object of the current user
  set_apps: 'apps', // Contains the app permission data and service number stored by name
  set_user_type: 'user_type', // Determines whether the user is a county or state user
  set_county_name: 'countyName', // Sets the selected County for Redistribution
  set_rep_user_type: 'repUserType', // Sets the user's permission level
};
