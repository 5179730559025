import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, useTheme } from '@mui/material';
import MuiButton from '@mui/material/Button';
import useStyles from './Button.styles';

function ProgressIcon() {
  return <CircularProgress color='inherit' size={22} sx={{ mr: 0.5 }} />;
}

const Button = ({ alternate, neutral, progress, fullWidth, ...props }) => {
  const theme = useTheme();
  const ButtonStyles = useStyles(theme);
  return (
    <MuiButton
      {...ButtonStyles}
      {...props}
      fullWidth={fullWidth}
      variant={alternate ? 'outlined' : 'contained'}
      color={neutral ? 'secondary' : props.color}
      startIcon={!progress && props.startIcon}
      endIcon={progress ? <ProgressIcon /> : props.endIcon}
      disableElevation>
      {props.children}
    </MuiButton>
  );
};

Button.prototype = {
  neutral: PropTypes.bool,
  alternate: PropTypes.bool,
  progress: PropTypes.bool,
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
};

Button.defaultProps = {
  neutral: false,
  alternate: false,
  progress: false,
  fullWidth: false,
};

export default Button;
