import { FullDateFormat } from '../../../utilities/utils';
import TrainingTypes from '../../../../common/const/TrainingTypes';

export const TrainingClassValue = (value) => {
  if (!value) return '';
  return TrainingTypes.find((item) => item.key === value).value;
};

const ClassesColumn = [
  {
    flex: 1,
    field: 'pvDataID',
    numeric: true,
    headerName: 'ID',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvHostCounty',
    numeric: false,
    headerName: 'Hosting Organization',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvTraining',
    numeric: false,
    headerName: 'Type of training',
    enabled: true,
    minWidth: 200,
    renderCell: (param) => TrainingClassValue(param.value),
  },
  {
    flex: 1,
    field: 'pvDate',
    numeric: false,
    headerName: 'Date',
    enabled: true,
    minWidth: 200,
    renderCell: (param) => FullDateFormat(param.value),
  },
  {
    flex: 1,
    field: 'pvParticipants',
    numeric: true,
    headerName: 'Number of participants',
    enabled: true,
    minWidth: 200,
  },
];

const AttendeesColumn = [
  {
    flex: 1,
    field: 'pvDataID',
    numeric: true,
    headerName: 'ID',
    enabled: false,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvName',
    numeric: false,
    headerName: 'Name',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvCounty',
    numeric: false,
    headerName: 'Organization',
    enabled: true,
    minWidth: 200,
  },
  {
    flex: 1,
    field: 'pvEmail',
    numeric: false,
    headerName: 'Email',
    enabled: true,
    minWidth: 200,
  },
];

export { ClassesColumn, AttendeesColumn };
