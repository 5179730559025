import { FIELDS, PREFIX } from '../const/milestone';
import reduce, { getFieldsByName } from '../util/baseReducer';

const initialState = {
  loading: false,
  initialized: false,
  milestoneDataset: [],
  milestoneCurrentEntry: {},
};

const FIELDS_BY_NAME = getFieldsByName(FIELDS);

export default function milestoneReducer(state = initialState, action) {
  const newState = {};
  reduce(action, state, newState, PREFIX, FIELDS_BY_NAME);
  return Object.assign({}, state, newState);
}
