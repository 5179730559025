import { useState } from 'react';
import apiClient from 'axios';

const useUploadDocForm = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const downloadForm = async (url, config) => {
    if (!url || !config || url.length < 10) return;
    if (inProgress) return;
    setInProgress(true);
    const response = await apiClient
      .get(url, {
        ...config,
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round((100 * progressEvent.loaded) / (progressEvent?.total ?? 0));
          console.log('onDownloadProgress: ', progress);
          setProgress(progress);
        },
      })
      .then((res) => {
        return { status: res.status, data: res.data };
      })
      .catch((res) => {
        console.error('🚀 downloadForm Hook res: ', res?.data);
        return { data: [] };
      });

    const respData = response.data;
    if (Array.isArray(respData)) {
      setUploadedDocuments(respData);
      setIsSuccess(true);
    }
    setInProgress(false);
  };

  const uploadForm = async (file, url, config) => {
    setInProgress(true);
    const formData = new FormData();
    formData.append('file', file);
    // noinspection JSUnusedGlobalSymbols
    const fileUploadResponse = await apiClient.post(url, formData, {
      ...config,
      onUploadProgress: (progressEvent) => {
        // const progress = (progressEvent.loaded / (progressEvent?.total ?? 0)) * 50;
        const progress = Math.round((100 * progressEvent.loaded) / (progressEvent?.total ?? 0));
        console.log('onUploadProgress: ', progress);
        setProgress(progress);
      },
    });
    setIsSuccess(true);
    setInProgress(false);
    return {
      status: fileUploadResponse.status,
      data: fileUploadResponse.data,
    };
  };

  return {
    inProgress,
    isSuccess,
    progress,
    uploadedDocuments,
    setUploadedDocuments,
    downloadForm,
    uploadForm,
  };
};

export default useUploadDocForm;
