const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    width: '100%',
  },
  title: {
    color: '#1C43B1',
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  card: {
    display: 'flex',
    py: '8px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '16px',
    flex: '1 0 0',
    width: '100%',
    borderBottom: '1px solid #E0E0E0',
  },
  headerTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#1F1F1F',
  },
  subContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
  },
  name: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#474747',
  },
  timeStamp: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#707070',
  },
});
export default useStyles;
