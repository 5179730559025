import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import CustomStatusComponent from '../../utilities/CustomStatus';
import { Field } from '../../utilities/ComponentWrappers';
import FormSection from './FormSection';
import {
  DeleteButton,
  DualTitleHeaderComponent,
  PrimaryButton,
} from '@zawarski/palmetto-ui-components';
import { Approved, Error, Loading, Submitted } from '../../utilities/StatusComponents';
import useWindowDimensions, { MOBILE_SIZE } from '../../hooks/useWindowDimensions';
import { CircularProgress, IconButton } from '@mui/material';
import { MdMenu } from 'react-icons/md';
import RequestChangeDialog from './RequestChangeDialog';
import moment from 'moment';

const InfoMessages = {
  ERR_SERVER: 'There was something wrong loading the entry.',
  ERR_SAVING: 'Error in saving data. Please contact an Administrator.',
  LOADING: 'Loading entry, please wait...',
  SUBMITTED:
    'The application has been sent back to the county for changes. Please check back again later for an update.',
};

const MagicUrlViewState = (props) => {
  const dispatch = useDispatch();
  const appLoading = useSelector((state) => state.rootReducer.appLoading);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [dlgOpen, setDlgOpen] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [countyName, setCountyName] = useState('');
  const [results, setResults] = useState({});
  const [status, setStatus] = useState('incomplete');
  const { width } = useWindowDimensions();
  const { token, id } = props.data;
  const palmettoUserAccount = {};

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const logOutUser = () => {
    sessionStorage.removeItem('magicToken');
    window.location.reload();
  };

  useEffect(() => {
    const fetchGroup = () => {
      return new Promise((resolve, reject) => {
        try {
          const resp = axios
            .get('https://www.palmettoeoc.com/api/groups')
            .then((res) => res.data || null);

          if (resp !== null) {
            return resolve(resp);
          } else {
            setErrMsg(InfoMessages.ERR_SERVER);
            setLoadError(true);
            reject(InfoMessages.ERR_SERVER);
          }
        } catch (error) {
          console.error('fetchGroup', error);
          return reject(null);
        }
      });
    };

    const fetchData = () => {
      return new Promise((resolve, reject) => {
        try {
          const resp = axios.get(
            `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/lcert/${id}?access_token=${token}`,
            {
              headers: {
                Authorization: token,
              },
            },
          );

          if (resp && resp.status === 200) {
            return resolve(resp.data);
          } else {
            return reject(InfoMessages.ERR_SERVER);
          }
        } catch (error) {
          console.error('fetchData', error);
          setErrMsg(InfoMessages.ERR_SERVER);
          setLoadError(true);
          reject(InfoMessages.ERR_SERVER);
          //setLoading(false);
          //await delay(5000); // Force User to redirect
          //logOutUser();
        }
      });
    };

    const fetchAll = async () => {
      const resp = await Promise.all([fetchGroup(), fetchData()]).then((res) => res);
      if (resp) {
        const counties = resp[0] || [];
        const entry = resp[1] || [];
        if (entry && Object.keys(entry).length >= 1) {
          if (counties && Object.keys(counties).length >= 1) {
            const newRef = JSON.parse(JSON.stringify(entry));
            const countyID = newRef.pvCounty;
            const countyName = counties.find((c) => c.pvGroupID === countyID).pvGroupTitle;
            setCountyName(countyName);
            setResults(newRef);
            setStatus(String(newRef.pvStatus).toLowerCase());
            setLoading(false);
          } else {
            setErrMsg(InfoMessages.ERR_SERVER);
            setLoadError(true);
            setLoading(false);
          }
        } else {
          setErrMsg(InfoMessages.ERR_SERVER);
          setLoadError(true);
          setLoading(false);
        }
      }
    };

    if (token !== null && parseInt(id) > 0) {
      setLoading(true);
      fetchAll();
    }
  }, []);

  const componentParams = {
    results,
  };

  const handleReqChanges = (data) => {
    data.pvStatus = 'Changes requested';
    _save(Object.assign({}, results, data));
  };

  const handleApprove = () => {
    results.pvStatus = 'Approved';
    results.pvDateApproved = moment().utc().toISOString();
    _save(results);
  };

  const apiSaveData = (payload) => {
    return new Promise( (resolve, reject) => {
      try {
        const pvDataID = payload.pvDataID;
        const URL = `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/rep/lcert/${pvDataID}?access_token=${token}`;
         axios({
          method: 'PUT',
          url: URL,
          data: payload,
          headers: {
            Authorization: token,
          },
        })
          .then((resp) => {
            return resolve('Success');
          })
          .catch((e) => {
            console.error(e);
            return reject('Failed');
          });
      } catch (error) {
        console.error(error);
        return reject('');
      }
    });
  };
  const _save = (toSave = results) => {
    setSaving(true);
    apiSaveData(toSave)
      .then(async () => {
        setSaving(false);
        setDlgOpen(false);
        setStatus(String(toSave.pvStatus).toLowerCase());
        await delay(10000); // Force User to redirect
        logOutUser();
      })
      .catch(async () => {
        setSaving(false);
        setDlgOpen(false);
        setErrMsg(InfoMessages.ERR_SAVING);
        setLoadError(true);
        //await delay(5000); // Force User to redirect
        //logOutUser();
      });
  };

  const editpage = (
    <div className='flex form-shell container-overflow-y'>
      {results.pvStatus === 'Submitted' ? (
        <div className='form-shell flex'>
          <div className='layout vertical '>
            <div className='flex'>
              <div className='form-margin'>
                <div className='height-20' />
                <div>
                  <span className='section-title'>Status</span>
                </div>
                <span className='card-slot-3r'>Status</span>

                <div className='height-10' />
                <CustomStatusComponent text={results?.pvStatus || ''} />
                <div className='height-10' />
              </div>

              {results?.pvStatus === 'Changes requested' && (
                <div className='layout horizontal'>
                  <Field
                    {...componentParams}
                    required={false}
                    label='Requested changes:'
                    field='pvReason'
                    multiline={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div className='height-10' />
      <span className='section-title form-margin'>Radiological plans</span>
      <FormSection
        {...componentParams}
        readOnly={true}
        stateView={true}
        checkField='pvReviewed'
        filesField='pvReviewedFiles'
        notesField='pvReviewedNotes'
        text='Radiological plans, a cross-reference between corresponding evaluation criteria (i.e. crosswalk), SOPs, and associated Letters of Agreement were reviewed this year for accuracy and completeness.'
        notesText={
          <>
            <b>Note:</b> Please upload copies of all changes below
          </>
        }
        pvGlobalID={`${results.pvGlobalID}-reviewed`}
        userAccount={palmettoUserAccount}
      />
      <div className='height-20' />
      <span className='section-title form-margin'>Emergency Response Training</span>
      <FormSection
        {...componentParams}
        readOnly={true}
        stateView={true}
        checkField='pvTrainingCompleted'
        filesField='pvTrainingCompletedFiles'
        notesField='pvTrainingCompletedNotes'
        text='Annual radiological emergency response training was accomplished this year.'
        notesText={
          <>
            <b>Note:</b> Please upload copies of completed training rosters
          </>
        }
        pvGlobalID={`${results.pvGlobalID}-training`}
        userAccount={palmettoUserAccount}
      />
      <div className='height-20' />
      <span className='section-title form-margin'>Protracted Activation Staffing</span>
      <FormSection
        {...componentParams}
        readOnly={true}
        stateView={true}
        checkField='pvTrainedStaffAvailable'
        filesField='pvTrainedStaffAvailableFiles'
        notesField='pvTrainedStaffAvailableNotes'
        text='Sufficiently trained and capable staff is available to maintain 24-hour capability for protracted activiation.'
        notesText={
          <>
            <b>Note:</b> Please maintain a copy of 24-hour staffing roster for review during FEMA
            staff asstance visit (SAV).
          </>
        }
      />
      <div className='height-20' />
      <span className='section-title form-margin'>Radiological survey instrument checks</span>
      <FormSection
        {...componentParams}
        readOnly={true}
        stateView={true}
        checkField='pvEquipmentChecked'
        filesField='pvEquipmentCheckedFiles'
        notesField='pvEquipmentCheckedNotes'
        text='Inspection, inventory, and operational checks were made of survey instruments used for radiological monitoring at least once each calendar quarter and upon each use.'
        notesText={
          <>
            <b>Note:</b> Please maintain a copy of records for review during FEMA SAV, including:
            <ul>
              <li>Type of equipment</li>
              <li>Quantity of equipment</li>
              <li>Location of equipment</li>
              <li>Calibration frequency</li>
              <li>Dates of inspection/inventory check</li>
            </ul>
          </>
        }
      />
      <div className='height-20' />
      <span className='section-title form-margin'>dosimetry tests</span>
      <FormSection
        {...componentParams}
        readOnly={true}
        stateView={true}
        checkField='pvDosimetryTestedInspected'
        filesField='pvDosimetryTestedInspectedFiles'
        notesField='pvDosimetryTestedInspectedNotes'
        text='Direct-reading dosimetry has been tested for accuracy and inspected for leakage this year.'
        notesText={
          <>
            <b>Note:</b> Please maintain a copy of records for review during FEMA SAV, including:
            <ul>
              <li>Type of equipment</li>
              <li>Quantity of equipment</li>
              <li>Location of equipment</li>
              <li>Calibration frequency</li>
              <li>Dates of calibration</li>
            </ul>
          </>
        }
      />
      <div className='height-20' />
      {results.pvStatus === 'Submitted' ? (
        <>
          <div className='layout horizontal form-margin'>
            {width >= MOBILE_SIZE ? <div className='flex'></div> : ''}
            <DeleteButton setwidth={width >= MOBILE_SIZE} onClick={() => setDlgOpen(true)}>
              Request Changes
            </DeleteButton>
            <div className='spacer-w16' />
            {saving && !dlgOpen ? (
              <PrimaryButton setwidth={width >= MOBILE_SIZE}>
                <CircularProgress color='neutral' />
              </PrimaryButton>
            ) : (
              <PrimaryButton
                setwidth={width >= MOBILE_SIZE}
                onClick={() => handleApprove()}
                disabled={dlgOpen && saving}>
                Approve
              </PrimaryButton>
            )}
          </div>
        </>
      ) : (
        ''
      )}
      <div className='height-20' />
    </div>
  );

  return (
    <div className='layout vertical full-height'>
      <DualTitleHeaderComponent
        icon={
          <IconButton>
            <MdMenu className='icon' />
          </IconButton>
        }
        titleBig={
          loadError ? 'Error' : loading ? '' : `${countyName || ''} • ${results?.pvYear || ''}`
        }
        titleSmall={loadError ? '' : loading ? '' : results.pvStatus}
      />
      <RequestChangeDialog
        {...results}
        open={dlgOpen}
        setOpen={setDlgOpen}
        results={results}
        onSubmit={handleReqChanges}
        saving={saving}
      />
      {loadError ? (
        <Error message={errMsg} />
      ) : loading ? (
        <Loading message={InfoMessages.LOADING} />
      ) : status === 'incomplete' || status === 'changes requested' ? (
        <Submitted message={InfoMessages.SUBMITTED} />
      ) : (
        editpage
      )}
    </div>
  );
};

export default MagicUrlViewState;
