import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TextField from '../TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from './SectionInput.styles';
import { Controller } from 'react-hook-form';

const SectionInput = (props) => {
  const classes = useStyles();
  return (
    <Box sx={props.customStyle ? props.customStyle : classes.body}>
      <Box sx={classes.input}>
        <Controller
          control={props.control}
          name={props.name}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              error={Boolean(error)}
              errMsg={error}
              value={value}
              onChange={onChange}
              InputProps={{
                readOnly: !props.isEdit,
                ...props.InputProps,
              }}
              helperTxt={error ? error.message : ''}
            />
          )}
        />
      </Box>
      <Box hidden={!props.isEdit} sx={classes.btn}>
        <DeleteIcon fontSize='small' onClick={() => props.onRemoveEntry(props.index)} />
      </Box>
    </Box>
  );
};

SectionInput.prototype = {
  isEdit: PropTypes.bool,
  index: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.any,
  control: PropTypes.any.isRequired,
  item: PropTypes.object,
  onRemoveEntry: PropTypes.func,
  customStyle: PropTypes.object,
};

export default SectionInput;
