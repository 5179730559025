const useStyles = (readOnly) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: '#F0F0F0',
    gap: '4px',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    borderRadius: '6px',
    border: '1px solid #E0E0E0',
    padding: '8px',
    position: 'relative',
    paddingTop: readOnly ? '8px !important' : '40px !important',
  },
  title: {
    textTransform: 'uppercase',
    color: '#707070',
    fontWeight: 700,
    fontSize: 12,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    opacity: 0.35,
    padding: '24px',
  },
  emptyText: {
    color: '#474747',
    fontSize: 12,
    fontWeight: 500,
  },
});
export default useStyles;
