import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { MdAccessTime } from 'react-icons/md';

const SubmittedMessage = (props) => {
  return (
    <Box className='layout vertical vertical-center full-height'>
      <span>
        <MdAccessTime
          style={{ color: 'rgba(0, 0, 0, 0.8)', width: '100%', heigth: '100%' }}
          size={52}
        />
      </span>
      <Box className='height-20'></Box>
      <span style={{ padding: '0 16px' }} className='text-center title-big opacity-54'>
        {props.message}
      </span>
    </Box>
  );
};

SubmittedMessage.prototype = {
  message: PropTypes.string,
};

export default SubmittedMessage;
