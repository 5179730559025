const useStyles = (theme, tabsClasses) => ({
  root: {
    bgcolor: 'transparent',
    width: '100%',
    maxWidth: '1440px',
    overflowX: 'auto',
  },
  tabs: {
    color: '#1F1F1F', // Default Font Color
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      textTransform: 'none', // Adjust text transformation
      fontWeight: 500,
      color: '#1F1F1F !important',
      height: '32px !important',
      margin: '0 4px!important',
      fontSize: '16px',
      minHeight: 'unset !important',
      borderRadius: '4px',
      backgroundColor: '#E0E0E0 !important',
      textWrap: 'nowrap',
      maxWidth: 'unset !important',
      minWidth: 'unset !important',
    },
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: '#0036FF !important',
      color: '#fff !important',
      height: '32px !important',
      margin: '0 4px!important',
      borderRadius: '4px',
      maxWidth: 'unset !important',
      minWidth: 'unset !important',
    },
    [`& .${tabsClasses.scrollButtons}`]: {
      height: '32px !important',
      '&.Mui-disabled': { opacity: 0 },
    },
  },
});

export default useStyles;
