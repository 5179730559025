import React from 'react';
import { createRoot } from 'react-dom/client';
import AppShell from './AppShell';
import store from './redux/store/store.js';
import { Provider } from 'react-redux';
import Toast from './components/utilities/Toast';
import { SnackbarProvider } from 'notistack';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import AppTheme from './theming/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { SW_INIT, SW_UPDATE } from "./redux/constants/action-types";
// window.location = '/#';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={AppTheme}>
      <Provider store={store}>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <AppShell />
          </LocalizationProvider>
          <Toast />
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
);
//ReactDOM.render(
//    <>
//    <Provider store={store}>
//      <SnackbarProvider>
//      <AppShell />
//      <Toast />
//      </SnackbarProvider>
//    </Provider>
//    </>,
//  document.getElementById('root')
//);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: SW_INIT }),
//   onUpdate: (registration) => {
//     store.dispatch({ type: SW_UPDATE, payload: registration })
//   },
// });
