import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { MdErrorOutline } from 'react-icons/md';

const ErrorMessage = (props) => {
  return (
    <Box className='layout vertical vertical-center full-height'>
      <MdErrorOutline
        style={{ color: 'rgba(0, 0, 0, 0.8)', width: '100%', heigth: '100%' }}
        size={52}
      />
      <div className='height-20'></div>
      <span style={{ padding: '0 16px' }} className='text-center title-big opacity-54'>
        {props.message}
      </span>
    </Box>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

export default ErrorMessage;
