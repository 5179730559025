import React, { useEffect, useRef, useState } from 'react';
import { DateField, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import PropTypes from 'prop-types';
import TextField from '../TextField';
import useStyles from './DatePickerField.styles';

const inputNoError = {
  '& .Mui-error:before': {
    borderBottomColor: '#757575 !important', // Remove the red color
  },
  '& .Mui-error:after': {
    borderBottomColor: 'var(--pcl-primary-background-color) !important', // Remove the red color
  },
};

const DatePickerField = ({ errMsg, helperTxt, required, fieldOnly, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dateInput = useRef(null);
  const format = props.format || 'MM/DD/YYYY';

  const toggleDatePicker = () => setOpen(!open);

  const closeDatePicker = () => setOpen(false);

  useEffect(() => {
    props.refFunc &&
      props.refFunc({
        inputRef: dateInput.current,
        toggleDatePicker: toggleDatePicker,
        closeDatePicker: closeDatePicker,
      });
  }, []);

  if (fieldOnly) {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateField
          disabled={props.disabled}
          label={props.label}
          value={props.value}
          onChange={props.onChange}
          format={format}
          slotProps={{ textField: { variant: 'filled' } }}
          slots={{ textField: TextField, ...props.slots }}
          sx={[classes.root, !required && inputNoError]}
        />
      </LocalizationProvider>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disabled={props.disabled}
        format={format}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        slotProps={{ textField: { variant: 'filled' } }}
        renderInput={(params) => <TextField {...params} />}
        sx={[classes.root, !required && inputNoError]}
      />
    </LocalizationProvider>
  );
};

DatePickerField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  required: PropTypes.bool,
  id: PropTypes.string,
  helperTxt: PropTypes.string,
  format: PropTypes.string,
  fieldOnly: PropTypes.bool,
};

DatePickerField.defaultProps = {
  disabled: false,
  required: false,
  fieldOnly: false,
  label: '',
  placeholder: '',
  value: '',
  error: '',
  helperTxt: '',
};

export default DatePickerField;
