import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './FileCard.styles';
import { Box, Checkbox } from '@mui/material';
import { FaRegFileLines } from 'react-icons/fa6';
import { Typo } from '../index';
import IconButton from '@mui/material/IconButton';
import { MdClear } from 'react-icons/md';
import useWindowDimensions, { MOBILE_SIZE } from '../../hooks/useWindowDimensions';
import PrintIcon from '@mui/icons-material/Print';

const FileCard = (props) => {
  const { width } = useWindowDimensions();
  const isMobile = width <= MOBILE_SIZE;
  const classes = useStyles(isMobile);

  return (
    <Box
      sx={[classes.file, props.isChecked ? classes.borderSelected : null]}
      onClick={() => (props.onClickFileCard ? props.onClickFileCard(props.item) : null)}>
      <Box>
        {props.isChecked ? (
          // <Checkbox
          //   checked={props.isChecked}
          //   color='primary'
          //   onChange={() => console.log('on click check box')}
          //   defaultChecked={false}
          //   value='text'
          //   sx={{ padding: '0' }}
          // />
          <PrintIcon fontSize='medium' sx={{ color: '#2961FD' }} />
        ) : (
          <FaRegFileLines size={24} />
        )}
      </Box>
      <Box sx={classes.fileNameWrapper}>
        <Typo sx={classes.title}>{props.title}</Typo>
        <Typo sx={classes.subTitle}>{props.subTitle}</Typo>
      </Box>
      <Box sx={classes.removeBtn} hidden={props.readOnly}>
        <IconButton
          aria-label='Delete'
          aria-haspopup='true'
          onClick={
            props.removeFile
              ? (e) => {
                  e.stopPropagation();
                  props.removeFile(props.useIndexValue ? props.mainIndex : props.item);
                }
              : undefined
          }>
          <MdClear className='icon-small' />
        </IconButton>
      </Box>
    </Box>
  );
};

FileCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  item: PropTypes.object,
  readOnly: PropTypes.bool,
  removeFile: PropTypes.func,
  mainIndex: PropTypes.number,
  useIndexValue: PropTypes.bool,
  onClickFileCard: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default FileCard;
