import { Checkbox, Input, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  CustomInputLabel,
  NormalTextField,
  PalmettoDatePicker,
  RequiredDropdown,
  RequiredTextField,
} from '@zawarski/palmetto-ui-components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const NormalTextFieldFunc = (props) => {
  const [value, setValue] = useState(props.value || '');
  const updateValue = (e) => {
    setValue(e.target.value);
    props.callback && props.callback(e.target.value);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  if (props.FIX) {
    // Note: If I put value={value} before the {...props}, this breaks the old Component Functions
    return <NormalTextField {...props} value={value} onChange={(e) => updateValue(e)} />;
  }
  return <NormalTextField value={value} {...props} onChange={(e) => updateValue(e)} />;
};

NormalTextFieldFunc.propTypes = {
  value: PropTypes.string,
  callback: PropTypes.func,
};

export const RequiredTextFieldFunc = (props) => {
  const [value, setValue] = useState(props.value || '');

  const updateValue = (e) => {
    setValue(e.target.value);
    props.callback && props.callback(e.target.value);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const passthroughProps = {};
  Object.assign(passthroughProps, props, { required: false }); // Removes the asterisk

  // Note: If I put value={value} before the {...props}, this breaks the old Component Functions
  if (props.FIX) {
    return (
      <RequiredTextField {...passthroughProps} value={value} onChange={(e) => updateValue(e)} />
    );
  }
  return <RequiredTextField value={value} {...passthroughProps} onChange={(e) => updateValue(e)} />;
};

RequiredTextFieldFunc.propTypes = {
  value: PropTypes.string,
  callback: PropTypes.func,
};

export const PalmettoDatePickerFunc = (props) => {
  const [date, setDate] = useState(props?.value || null);
  const { callback, value, ...other } = props;
  const [datePickerRef, setDatePickerRef] = useState({});

  const onChange = (event, value) => {
    setDate(value);
    callback && callback(value);
  };

  const DateRef = (ref) => {
    setDatePickerRef(ref);
  };

  return (
    <>
      <PalmettoDatePicker
        {...other}
        value={date}
        refFunc={DateRef}
        onChange={onChange}
        onClose={datePickerRef.closeDatePicker}
      />
    </>
  );
};

PalmettoDatePickerFunc.propTypes = {
  value: PropTypes.string,
  callback: PropTypes.func,
};

const NEW_PAGE_DROPDOWN_MENU_PROPS = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  PaperProps: {
    style: {
      minWidth: '250px',
      width: '100%',
    },
  },
};

export const DropdownMenuNotRequired = (props) => {
  let field = props.field; // Field in the Results to set
  let values = props.values || []; // Possible Selections (Array of Strings)
  let name = props.name; // Field Label Text
  let id = props.id || props.fieldName; // id to use for input
  let placeholder = props.placeholder || '';

  const [value, setValue] = useState(props.value || '');

  const updateValue = (e) => {
    setValue(e.target.value);
    if (props.callback) {
      props.callback(e.target.value);
    }
  };

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  const menuProps = {};
  Object.assign(menuProps, NEW_PAGE_DROPDOWN_MENU_PROPS);

  if (props.forceLeft) {
    menuProps.anchorOrigin.horizontal = 'left';
  }

  return (
    <FormControl style={{ width: '100%' }} disabled={props.disabled || false}>
      <CustomInputLabel style={{ margin: '0 6px' }} shrink htmlFor={id}>
        {' '}
        {name} <span className=''></span>{' '}
      </CustomInputLabel>
      <RequiredDropdown
        value={value}
        field={field}
        onChange={(e) => updateValue(e)}
        input={<Input name={field} id={id}></Input>}
        displayEmpty
        custommargin='true'
        name={field}
        MenuProps={NEW_PAGE_DROPDOWN_MENU_PROPS}
        className='flex'>
        {placeholder ? (
          <MenuItem value='' disabled>
            <span className='placeholder'>{placeholder}</span>
          </MenuItem>
        ) : (
          ''
        )}
        {values.map((type, index) => {
          if (type && typeof type === 'object') {
            return (
              <MenuItem key={index} value={type.key}>
                {type.value}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            );
          }
        })}
      </RequiredDropdown>
    </FormControl>
  );
};

DropdownMenuNotRequired.propTypes = {
  field: PropTypes.string,
  values: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
  fieldName: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  callback: PropTypes.func,
  value: PropTypes.any,
};

export const DropdownMenuRequired = (props) => {
  let field = props.field; // Field in the Results to set
  let values = props.values || []; // Possible Selections (Array of Strings)
  let name = props.name; // Field Label Text
  let id = props.id || props.fieldName; // id to use for input
  let placeholder = props.placeholder || '';

  const [value, setValue] = useState(props.value || '');

  const updateValue = (e) => {
    setValue(e.target.value);
    if (props.callback) {
      props.callback(e.target.value);
    }
  };

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  const menuProps = {};
  Object.assign(menuProps, NEW_PAGE_DROPDOWN_MENU_PROPS);

  if (props.forceLeft) {
    menuProps.anchorOrigin.horizontal = 'left';
  }

  return (
    <FormControl style={{ width: '100%' }} error={props.error} disabled={props.disabled || false}>
      <CustomInputLabel error={props.error} style={{ margin: '0 6px' }} shrink htmlFor={id}>
        {' '}
        {name} <span className='required'></span>{' '}
      </CustomInputLabel>
      <RequiredDropdown
        value={value}
        field={field}
        onChange={(e) => updateValue(e)}
        input={<Input name={field} id={id}></Input>}
        displayEmpty
        custommargin='true'
        name={field}
        error={props.error}
        MenuProps={NEW_PAGE_DROPDOWN_MENU_PROPS}
        className='flex'>
        {placeholder ? (
          <MenuItem value='' disabled>
            <span className='placeholder'>{placeholder}</span>
          </MenuItem>
        ) : (
          ''
        )}
        {values.map((type, index) => {
          if (type && typeof type === 'object') {
            return (
              <MenuItem key={index} value={type.key}>
                {type.value}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            );
          }
        })}
      </RequiredDropdown>
    </FormControl>
  );
};

DropdownMenuRequired.propTypes = {
  field: PropTypes.string,
  values: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
  fieldName: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  callback: PropTypes.func,
  value: PropTypes.any,
};

export const MultiSelect = (props) => {
  let name = props.name; // Field Label Text
  let id = props.id || props.fieldName; // id to use for input
  // let placeholder = props.placeholder || '';
  let values = props.values || []; // Possible Selections (Array of Strings)
  let options = props.options || [];

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: '100%',
      },
    },
  };

  const onChangeSelect = (event) => {
    if (props.callback) props.callback(event);
  };

  return (
    <FormControl
      style={{ width: '100%', margin: '0 6px' }}
      error={props.error}
      disabled={props.disabled || false}>
      <CustomInputLabel error={props.error} style={{ margin: 0 }} shrink htmlFor={id}>
        {' '}
        {name} <span className='required'></span>{' '}
      </CustomInputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={values}
        onChange={onChangeSelect}
        renderValue={(selectedCounty) => selectedCounty.join(', ')}
        MenuProps={menuProps}
        variant='standard'>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={values.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultiSelect.propTypes = {
  callback: PropTypes.func,
  values: PropTypes.array,
  options: PropTypes.array,
  name: PropTypes.string,
};

export const DropdownMenuRequiredMin = (props) => {
  // let field = props.field; // Field in the Results to set
  let values = props.values || []; // Possible Selections (Array of Strings)
  let name = props.name; // Field Label Text
  let id = props.id || props.fieldName; // id to use for input
  let placeholder = props.placeholder || '';

  const [value, setValue] = useState(props.value || '');

  const updateValue = (e) => {
    setValue(e.target.value);
    if (props.callback) {
      props.callback(e.target.value);
    }
  };

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        paddingTop: 12,
      },
    },
  };

  return (
    <FormControl
      style={{ width: '100%', margin: '0 6px' }}
      error={props.error}
      disabled={props.disabled || false}>
      <CustomInputLabel error={props.error} style={{ margin: '0 12px 0 0' }} shrink htmlFor={id}>
        {' '}
        {name} <span className='required'></span>{' '}
      </CustomInputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        placeholder={placeholder}
        value={value}
        onChange={(e) => updateValue(e)}
        MenuProps={menuProps}
        variant='standard'
        sx={{ marginTop: 0 }}>
        {values.map((type, index) => {
          if (type && typeof type === 'object') {
            return (
              <MenuItem key={index} value={type.key}>
                {type.value}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
};

DropdownMenuRequiredMin.propTypes = {
  field: PropTypes.string,
  values: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
  fieldName: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  callback: PropTypes.func,
  value: PropTypes.any,
};

export const DropdownMenuNotRequiredMin = (props) => {
  // let field = props.field; // Field in the Results to set
  let values = props.values || []; // Possible Selections (Array of Strings)
  let name = props.name; // Field Label Text
  let id = props.id || props.fieldName; // id to use for input
  let placeholder = props.placeholder || '';

  const [value, setValue] = useState(props.value || '');

  const updateValue = (e) => {
    setValue(e.target.value);
    if (props.callback) {
      props.callback(e.target.value);
    }
  };

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        paddingTop: 12,
      },
    },
  };

  return (
    <FormControl
      style={{ width: '100%', margin: '0 6px' }}
      error={props.error}
      disabled={props.disabled || false}>
      <CustomInputLabel error={props.error} style={{ margin: '0 12px 0 0' }} shrink htmlFor={id}>
        {' '}
        {name} <span className=''></span>{' '}
      </CustomInputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        placeholder={placeholder}
        value={value}
        onChange={(e) => updateValue(e)}
        MenuProps={menuProps}
        variant='standard'
        sx={{ marginTop: 0 }}>
        {values.map((type, index) => {
          if (type && typeof type === 'object') {
            return (
              <MenuItem key={index} value={type.key}>
                {type.value}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
};

DropdownMenuNotRequiredMin.propTypes = {
  field: PropTypes.string,
  values: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
  fieldName: PropTypes.string,
  placeholder: PropTypes.string,
  callback: PropTypes.func,
  value: PropTypes.any,
};
