/**
 * Contains logic for uploading and downloading files using the FileLibrary App.
 */

import AWS from 'aws-sdk';

const BUCKET = 'fallbackbucket';

const S3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  signatureVersion: 'v4',
  params: {
    Bucket: BUCKET,
  },
});

// Parses Filelibrary v1 link to get the bucket and key for S3
function parseV1Link(uri) {
  // Format is /api/containers/<bucket>/download/<userID>%2F<filename>
  let split = uri.split('/');
  return {
    bucket: split[3],
    key: decodeURIComponent(split[5]),
  };
}

/**
 * Uploads new Files after the File Uploader Input Changes.
 * @param {FileList} files List of files to upload
 * @param {Function} progressUpdateFunc Function used to update the progress bar
 * @returns Returns the new FileObjects to Concatenate with teh existing ones
 */
export async function uploadFiles(files, progressUpdateFunc) {
  let size = 0;
  for (let i = 0; i < files.length; i++) {
    size += files[i].size;
  }
  let sizeObject = {}; // Used for keeping track of Uploaded File Progress
  let funcs = [];
  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    let fileKey = '' + new Date().getTime() + '__' + file.name;
    funcs.push(
      uploadToS3(file, fileKey, (key, loaded) => {
        // Calculate the Progress here
        if (!sizeObject[key]) sizeObject[key] = 0;
        sizeObject[key] = loaded;
        let loadedSize = 0;
        // Calculate total loaded size
        Object.keys(sizeObject).forEach((file) => (loadedSize += sizeObject[file]));
        if (progressUpdateFunc) {
          progressUpdateFunc(loadedSize / size);
        }
      }),
    );
  }
  return await Promise.all(funcs);
}

/**
 * This removes the Date and DUMMYSTRING from the file so the user gets their regular filename back
 * @param {Object} payload The object in which the filename possibly containing the dummystring (Older files won't)
 * @returns {String} The filename without the key padding string (Or with it if it's an older file)
 */
export function _formatFileNameBeforeDownload(payload) {
  let name = payload.pvKey;
  let filename = '';
  //splice out the dummy string if it exists (If it doesn't it's an old file)
  if (name && name.indexOf('_DUMMYSEARCHSTRING_') >= 0) {
    filename = name.substring(name.indexOf('_DUMMYSEARCHSTRING_') + 19);
  }

  if (name && name.indexOf('%2F') >= 0) {
    filename = name.substring(name.indexOf('%2F') + 3);
  }

  // this means there was no title entered when the file was uploaded
  if (filename === payload.pvName) {
    let ext = filename.substring(filename.lastIndexOf('.') + 1);
    let filename_without_ext = filename.substring(0, filename.lastIndexOf('.'));
    let filename_without_ts = filename_without_ext.substring(
      0,
      filename_without_ext.lastIndexOf('_'),
    );

    return filename_without_ts.length ? filename_without_ts : filename_without_ext + '.' + ext;
  } else {
    // this means the file name was entered, so use that
    let ext = filename.substring(filename.lastIndexOf('.') + 1);
    return payload.pvName + '.' + ext;
  }
}

export function downloadFile(fileObj) {
  let payload = fileObj;
  let a = document.createElement('a');
  a.href = fileObj.link;
  a.target = '_blank';
  a.download = _formatFileNameBeforeDownload(payload);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// Adds the link to all files
export async function getFiles(uploadedFiles) {
  let funcs = [];
  uploadedFiles.forEach((fileObj) => {
    if (!fileObj.pvKey) {
      return;
    }
    let keybucket;
    if (fileObj.v2 !== 1) {
      // This is a v1 file, parse the link
      keybucket = parseV1Link(fileObj.pvKey);
    } else {
      keybucket = {
        key: fileObj.pvKey,
        bucket: BUCKET,
      };
    }
    funcs.push(getLink(fileObj));
  });
  await Promise.all(funcs);
  return uploadedFiles;
}

export function getLink(fileObj) {
  if (!fileObj.pvKey) {
    return;
  }
  let keybucket = null;

  keybucket = {
    key: fileObj.pvKey,
    bucket: BUCKET,
  };

  return new Promise((resolve, reject) => {
    S3.getSignedUrl(
      'getObject',
      {
        Bucket: keybucket.bucket,
        Key: keybucket.key,
      },
      (err, link) => {
        if (err) {
          reject(err);
        } else {
          fileObj.link = link;
          resolve(fileObj);
        }
      },
    );
  });
}

// ===== Helper Functions ===== //

/**
 * Returns number of uploaded files
 * @param {Array} uploadedFiles Array of Uploaded File Objects
 */
export function getNumOfFiles(uploadedFiles) {
  let toRet = 0;
  uploadedFiles.forEach((file) => {
    if (!file.deleted) toRet++;
  });
  return toRet;
}

// Gets the actual filename from the key
export function getProperKeyName(key) {
  if (key) {
    let a = key.substring(key.indexOf('/') + 2);
    return a.substring(a.indexOf('__') + 2, a.length);
  }

  return key;
}

export const getProperKeyNameDummySearchString = (name) => {
  if (name && name.indexOf('_DUMMYSEARCHSTRING_') >= 0) {
    return name.substring(name.indexOf('_DUMMYSEARCHSTRING_') + 19);
  }
  return name;
};

/**
 * Returns the Size of the file from a bytes value
 * @param {Number} bytes Number of Bytes
 */
export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  // eslint-disable-next-line
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

// Uploads to an S3 Bucket
// Use Callback to update progress
export function uploadToS3(file, key, callback) {
  return new Promise((resolve, reject) => {
    // Upload to S3
    let managedUpload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: BUCKET,
        Key: key,
        Body: file,
      },
    });

    managedUpload.on('httpUploadProgress', function (evt) {
      callback && callback(key, evt.loaded);
    });

    managedUpload
      .promise()
      .then((data) => {
        let fileObj = {
          key: key,
          size: file.size,
          type: file.type,
          name: file.name,
          deleted: false,
          associated: false,
        };
        S3.getSignedUrl(
          'getObject',
          {
            Bucket: BUCKET,
            Key: data.Key,
          },
          (err, link) => {
            if (err) {
              reject(err);
            } else {
              fileObj.downloadLink = link; // Temporary Link to use for viewing and downloading the file
              resolve(fileObj);
            }
          },
        );
      })
      .catch((err) => {
        reject(err);
      });
  });
}
