import React from 'react';
import FormControl from '@mui/material/FormControl';
import { CustomInputLabel } from '@zawarski/palmetto-ui-components';
import MSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import PropTypes from 'prop-types';

const DropDownMenu = (props) => {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        paddingTop: 12,
      },
    },
  };

  return (
    <FormControl style={{ width: '100%', margin: 0 }}>
      <CustomInputLabel error={props.error} shrink htmlFor={props.id} style={{ margin: 0 }}>
        {props.label} <span className={props.required ? 'required' : ''}></span>{' '}
      </CustomInputLabel>
      <MSelect
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        disabled={props.disabled}
        error={props.error}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        MenuProps={menuProps}
        variant='standard'
        sx={{ marginTop: 0 }}>
        {props.values && props.values.length
          ? props.values.map((type, index) => {
              if (type && typeof type === 'object') {
                return (
                  <MenuItem key={index} value={type.key}>
                    {type.value}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                );
              }
            })
          : null}
      </MSelect>
      {props.error ? (
        <FormHelperText error={props.error} style={{ margin: 0 }}>
          {props.helperTxt}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

DropDownMenu.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  values: PropTypes.array,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  required: PropTypes.bool,
  id: PropTypes.string,
  helperTxt: PropTypes.string,
};

export default DropDownMenu;
