import React from 'react';
import '../../styles/custom_status.css';
import { StatusComponent } from '@zawarski/palmetto-ui-components';

export default function CustomStatusComponent({ text, ...props }) {
  const Status2ClassObj = {
    'changes requested': ' s-container  status-text changes-requested-status ',
    submitted: ' s-container status-text submitted-status ',
    approved: '   s-container status-text approved-status ',
    incomplete: ' s-container  status-text incomplete-status ',
  };

  return <StatusComponent statusToClass={Status2ClassObj} text={text} />;
}
