import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { TimeLineType, TimeLineTypeIcon } from './Constants';
import { TextField, Typo } from '../index';
import useStyles from './ActivityFeed.styles';
import moment from 'moment-timezone';

moment.tz.setDefault('America/New_York');

const ActivityFeedView = ({ dataSet, ...props }) => {
  const classes = useStyles();
  const isEmpty = !dataSet || dataSet.length === 0;

  return (
    <Box sx={classes.container}>
      <Typo sx={[classes.title, { padding: props.padding }]}>{props.title}</Typo>
      <Timeline
        hidden={isEmpty}
        sx={{
          display: 'flex',
          width: '100%',
          background: '#FFF',
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}>
        {dataSet && dataSet.length > 0
          ? dataSet.map((item, idx) => {
              const isLastChild = idx === dataSet.length - 1;
              const isCompleted = item.pvActionType === TimeLineType.completed;
              const isInfo = item.pvActionType === TimeLineType.info;
              const isSectionFieldEmpty = item.pvSectionNoteField === null;
              const LeftNoteEl = isSectionFieldEmpty ? (
                <span style={{ fontWeight: 400, fontSize: '16px' }}>left a note</span>
              ) : (
                <>
                  <span style={{ fontWeight: 400, fontSize: '16px' }}>left a note </span>
                  <span style={{ fontWeight: 700, fontSize: '16px' }}>
                    {item.pvSectionNoteField}
                  </span>
                </>
              );

              return (
                <TimelineItem key={item.id || item.pvDataID || idx}>
                  <TimelineSeparator>
                    <TimelineDot sx={{ background: '#EBEBEB' }}>
                      {TimeLineTypeIcon[item.pvActionType]}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box
                      sx={[
                        classes.card,
                        isLastChild ? { borderBottom: 'unset !important', mb: '20px' } : {},
                        isCompleted || isInfo ? { pb: '24px' } : {},
                      ]}>
                      <Box>
                        <Typo variant={'body1'} sx={classes.headerTitle}>
                          {item.pvTitle}{' '}
                          {item.pvActionType === TimeLineType.comments ? LeftNoteEl : ''}
                        </Typo>
                        <Box sx={classes.subContent}>
                          <Typo variant={'body1'} sx={classes.name}>
                            {item.pvSubTitle}
                          </Typo>
                          <Typo variant={'body1'} sx={classes.timeStamp}>
                            {moment(item.pvCreateDate).format('MM/DD/YYYY hh:mm A')}
                          </Typo>
                        </Box>
                      </Box>
                      {!isCompleted ? (
                        item.pvNotes !== null ? (
                          <TextField
                            label='Note'
                            multiline
                            value={item.pvNotes}
                            style={{ width: '100%' }}
                          />
                        ) : null
                      ) : null}
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              );
            })
          : null}
      </Timeline>
    </Box>
  );
};

ActivityFeedView.propTypes = {
  title: PropTypes.string,
  padding: PropTypes.string,
  dataSet: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      pvDataID: PropTypes.number,
      pvStatus: PropTypes.string,
      pvActionType: PropTypes.string,
      pvCreateDate: PropTypes.string,
      pvNotes: PropTypes.string,
      pvTitle: PropTypes.string,
      pvSubTitle: PropTypes.string,
    }),
  ),
};

ActivityFeedView.defaultProps = {
  title: 'Activity Feed',
  padding: '16px 24px',
  dataSet: [],
};

export default ActivityFeedView;
