import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { FaRegFileLines } from 'react-icons/fa6';
import { MdClear } from 'react-icons/md';
import Typo from '../Typo';
import useStyles from './FileContainer.styles';

const FileContainer = (props) => {
  const classes = useStyles();

  return (
    <Box
      sx={classes.file}
      onClick={props.downloadFile ? () => props.downloadFile(props.item) : undefined}>
      <Box sx={classes.rowContainer}>
        <Box>
          <FaRegFileLines size={24} />
        </Box>
        <Box sx={classes.fileNameWrapper}>
          <Typo sx={classes.fileName}>{props.name}</Typo>
          <Typo sx={classes.fileSize}>{props.bytes}</Typo>
        </Box>
        <Box sx={classes.removeBtn} hidden={props.readOnly}>
          <IconButton
            aria-label='Delete'
            aria-haspopup='true'
            onClick={props.removeFile ? () => props.removeFile(props.item) : undefined}>
            <MdClear className='icon-small' />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

FileContainer.propTypes = {
  name: PropTypes.string,
  bytes: PropTypes.string,
  item: PropTypes.object,
  downloadFile: PropTypes.func,
  removeFile: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default FileContainer;
