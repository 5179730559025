/**
 * Contains Milestone Redux Constants
 */

export const PREFIX = 'milestone';

export const FIELDS = [
  {
    name: 'loading', // Name of the field to expose in the reducer and for dispatch (takes name from field if not included)
    field: 'loading', // Name of the field in the state object
    setter: true, // Add setters
    array: false, // Add upsert and remove for arrays
  },
  {
    name: 'has_update',
    field: 'hasUpdate',
    setter: true,
  },
  {
    name: 'milestone_dataset',
    field: 'milestoneDataset',
    setter: true,
    array: true,
  },
  {
    name: 'milestone_current_entry',
    field: 'milestoneCurrentEntry',
    setter: true,
  },
];
