import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import Typo from '../Typo';
import DocumentWrapper from '../DocumentWrapper';
import useStyles from './SectionForm.styles';
import { TextField } from '../index';

const SectionForm = ({
  text,
  stateView,
  checkField,
  notesText,
  notesField,
  pvGlobalID,
  userAccount,
  onFileQueued,
  onFileDeleted,
  ...props
}) => {
  const classes = useStyles();
  const { control } = useFormContext();

  const handleCheck = (onChange) => (event) => {
    const { checked } = event.target;
    if (checked) {
      onChange(1);
    } else {
      onChange(0);
    }
  };

  return (
    <Box sx={classes.container}>
      <Box sx={classes.titleWrapper}>
        <Typo sx={classes.title}>{props.title}</Typo>
      </Box>
      <Box sx={classes.wrapper}>
        <Controller
          control={control}
          name={checkField}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  // disabled={props.readOnly}
                  checked={Number(value) === 1}
                  onChange={!props.readOnly ? handleCheck(onChange) : undefined}
                  name={checkField}
                  readOnly={props.readOnly}
                />
              }
              label={text}
            />
          )}
        />

        <p hidden={stateView}>{notesText ? notesText : null}</p>

        {pvGlobalID && userAccount ? (
          <DocumentWrapper
            readOnly={props.readOnly}
            title={props.reportTitle}
            uuid={pvGlobalID}
            user={userAccount}
            filesQueuedCallback={onFileQueued}
            filesDeletedCallback={onFileDeleted}
          />
        ) : null}

        <div className='layout horizontal' hidden={stateView}>
          <Controller
            control={control}
            name={notesField}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                error={Boolean(error)}
                errMsg={error}
                value={value}
                onChange={!props.readOnly ? onChange : undefined}
                label='Notes'
                placeholder='Enter notes'
                style={{ width: '100%' }}
                required={false}
                helperTxt={error ? error.message : ''}
              />
            )}
          />
        </div>
      </Box>
    </Box>
  );
};

SectionForm.propTypes = {
  title: PropTypes.string,
  readOnly: PropTypes.bool,
  stateView: PropTypes.bool,
  checkField: PropTypes.string,
  notesField: PropTypes.string,
  text: PropTypes.string,
  pvDataID: PropTypes.number,
  notesText: PropTypes.node,
  reportTitle: PropTypes.string,
  pvGlobalID: PropTypes.string,
  userAccount: PropTypes.object,
  onFileQueued: PropTypes.func,
  onFileDeleted: PropTypes.func,
};

SectionForm.defaultProps = {
  title: '',
  readOnly: false,
};

export default SectionForm;
