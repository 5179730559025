import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Fade, Modal, Paper } from '@mui/material';
import useStyles from './ModalView.styles';

const ModalView = (props) => {
  const classes = useStyles();

  return (
    <Modal
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      {...props}>
      <Fade in={props.open}>
        <Paper sx={props.contentStyle ? props.contentStyle : classes.modalContent} elevation={1}>
          {props.children}
        </Paper>
      </Fade>
    </Modal>
  );
};

ModalView.propTypes = {
  contentStyle: PropTypes.object,
};

export default ModalView;
