/**
 * Contains the components that make up each form section
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NormalTextField } from '@zawarski/palmetto-ui-components';
import { Checkbox } from '@mui/material';
import PalmettoFileWrapper from '../../fileUploader/PalmettoFileWrapper';

export default function FormSection({
  readOnly,
  stateView,
  checkField,
  // filesField,
  notesField,
  text,
  notesText,
  results,
  onResultsChange,
  // validate,
  pvGlobalID,
  onFileQueued,
  onFileDeleted,
  userAccount,
}) {
  const [checked, setChecked] = useState(false);
  const [notes, setNotes] = useState('');

  const handleChange = (value, setter, field) => {
    if (readOnly) return;
    setter(value);
    onResultsChange(field, value);
  };

  useEffect(() => {
    if (results) {
      if (results[checkField] && results[checkField] !== checked) {
        setChecked(results[checkField]);
      }

      if (results[notesField] && results[notesField] !== notes) {
        setNotes(results[notesField]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);
  return (
    <>
      <div className='layout horizontal'>
        <div>
          <Checkbox
            color='primary'
            checked={checked}
            onChange={(e) => handleChange(e.target.checked ? 1 : 0, setChecked, checkField)}
          />
        </div>
        <div className='flex'>
          <p>{text}</p>
          <p hidden={stateView}>{notesText}</p>
          {pvGlobalID && userAccount ? (
            <PalmettoFileWrapper
              readonly={readOnly}
              uuid={pvGlobalID}
              user={userAccount}
              filesQueuedCallback={onFileQueued}
              filesDeletedCallback={onFileDeleted}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <div hidden={stateView} className='layout horizontal'>
        <NormalTextField
          label='Notes'
          placeholder='Enter notes'
          textarea={true}
          multiline
          value={notes}
          onChange={(e) => handleChange(e.target.value, setNotes, notesField)}
          className='flex'
          custommargin='true'
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </div>
    </>
  );
}

FormSection.propTypes = {
  readOnly: PropTypes.bool,
};

FormSection.defaultProps = {
  readOnly: false,
};
