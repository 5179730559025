/**
 * Manages the Letters of Certification state.
 *
 * Note: the action names follow this pattern: <prefix>/<set/upsert/remove>_<field_name>
 */

import { FIELDS, PREFIX } from '../const/equipment';
import reduce, { getFieldsByName } from '../util/baseReducer';

const initialState = {
  loading: false,
  hasUpdate: false,
  initialized: false,
  // Data for views
  equipmentDataset: [],
  calibrationDataset: [],
  equipmentCurrentEntry: {},
  calibrationCurrentEntry: {},
  selectedTab: 'equipment',
  organizationID: 0,
  page: 1,
  pageLimit: 15,
  initialPageTotal: 0,
  pageTotal: 0,
};

const FIELDS_BY_NAME = getFieldsByName(FIELDS);

export default function equipmentReducer(state = initialState, action) {
  const newState = {};
  reduce(action, state, newState, PREFIX, FIELDS_BY_NAME);
  return Object.assign({}, state, newState);
}
