// TODO Move this into a palmetto-ui-components

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { MdClear } from 'react-icons/md';

const useStyles = makeStyles((theme) => {
  return {
    close: {
      //padding: theme.spacing.unit / 2,
      padding: theme.spacing(0.5),
    },
  };
});

const Toast = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    console.log('In Toast');
    window.addEventListener('show-toast', (event) => {
      //this.setState({ message: event.detail.message });
      //this.setState({ open: true });
      setMessage(event.detail.message);
      setOpen(true);
    });
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //const ContentAlert = (msg) => {
  //  return (
  //    <React.Fragment>
  //      <span id="message-id">{msg}</span>
  //      <IconButton
  //        key="close"
  //        aria-label="Close"
  //        color="inherit"
  //        className={classes.close}
  //        onClick={handleClose}>
  //        <MdClear />
  //      </IconButton>
  //    </React.Fragment>
  //  );
  //};

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
      />
    </div>
  );
};

//class Toast extends React.Component {
//  state = {
//    open: false,
//    message: "",
//  };

//  componentDidMount() {
//    console.log("In Toast");
//    window.addEventListener("show-toast", (event) => {
//      this.setState({ message: event.detail.message });
//      this.setState({ open: true });
//    });
//  }

//  handleClick = () => {
//    this.setState({ open: true });
//  };

//  handleClose = (event, reason) => {
//    if (reason === "clickaway") {
//      return;
//    }

//    this.setState({ open: false });
//  };

//  render() {
//    const classes = useStyles();
//    return (
//      <div>
//        <Snackbar
//          anchorOrigin={{
//            vertical: "bottom",
//            horizontal: "left",
//          }}
//          open={this.state.open}
//          autoHideDuration={6000}
//          onClose={this.handleClose}
//          ContentProps={{
//            "aria-describedby": "message-id",
//          }}
//          message={<span id="message-id">{this.state.message}</span>}
//          action={[
//            <IconButton
//              key="close"
//              aria-label="Close"
//              color="inherit"
//              className={classes.close}
//              onClick={this.handleClose}>
//              <MdClear />
//            </IconButton>,
//          ]}
//        />
//      </div>
//    );
//  }
//}

//Toast.propTypes = {
//  classes: PropTypes.object.isRequired,
//};

export default Toast;
