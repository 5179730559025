import { useMemo } from 'react';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const defaultValues = {
  name: '',
  email: '',
  county: '',
};

function useRegisterForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().required('Name is required'),
        email: string().nullable(),
        county: string().required('County is required'),
      }),
    [],
  );

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default useRegisterForm;
