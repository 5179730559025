const useStyles = (theme) => ({
  root: {},
  containedSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
});

export default useStyles;
