const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: '#fff',
    width: '100%',
    padding: '24px',
    gap: '8px',
    borderRadius: '4px',
    boxShadow: '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    color: '#1C43B1',
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    gap: '8px',
    fontSize: '12px',
    bgcolor: '#E0E0E0',
    color: '#1F1F1F',
    padding: '8px 16px',
    borderRadius: '4px',
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      bgcolor: '#D8D8D8',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    py: '8px',
    width: '100%',
    height: 'auto',
    gap: '10px',
  },
});

export default useStyles;
