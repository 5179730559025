import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, Input, InputLabel, useTheme } from '@mui/material';
import Typo from '../Typo';
import useStyles from './TFieldMask.styles';

const TFieldMask = ({ label, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Box>
      <FormControl variant='standard' sx={{ width: '100%' }}>
        <InputLabel htmlFor='input-with-icon-adornment' sx={{ mb: '5px' }}>
          {label}
        </InputLabel>
        <Box sx={classes.inputWrapper}>
          <Typo sx={classes.mask}>{props.mask} </Typo>
          <Input
            id='input-with-mask'
            sx={classes.input}
            InputLabelProps={{ shrink: true, color: props.error ? 'error' : 'secondary' }}
            style={{ width: '100%' }}
            {...props}
          />
        </Box>
      </FormControl>
    </Box>
  );
};

TFieldMask.prototype = {
  mask: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

TFieldMask.defaultProps = {
  mask: '',
  label: 'Name',
};

export default TFieldMask;
