/**
 * This file contains most of the actions used around the application
 */

import { apiClient } from '../../common/axios/axios_instance';
import { getUsersSavedIncidents } from './incidents';

/**
 * This sets the default info right after the user logs in
 * @param {Object} payload
 */
export function setDefaultInfo(payload) {
  return async function (dispatch, getState) {
    dispatch({ type: 'set_groups', payload: JSON.parse(JSON.stringify(payload.groups)) });
    dispatch({ type: 'set_user', payload: payload.accountInfo });
    dispatch({ type: 'set_login_state', payload: true });
    dispatch({ type: 'set_rep_user_type', payload: payload.repUserType });

    // Get users selected incidents
    let fIns = {
      where: {
        and: [
          {
            pvAccountID: payload.accountInfo.id,
          },
          {
            pvSettingType: 'selectedIncidents',
          },
        ],
      },
    };
    let incidentSettingsRes = await apiClient.get(
      process.env.REACT_APP_PALMETTO_ENDPOINT + '/api/settings?filter=' + JSON.stringify(fIns),
    );

    const userSettings = [];
    if (incidentSettingsRes && incidentSettingsRes.data && incidentSettingsRes.data.length) {
      userSettings.push(incidentSettingsRes.data[0]);
    }
    dispatch({ type: 'set_user_settings', payload: userSettings });
    payload.settings = userSettings;

    // Get the selected group Object
    let found = false;
    for (const grp of payload.groups) {
      if (grp.pvGroupID === payload.selectedGroupID) {
        found = true;
        dispatch({ type: 'set_selected_group', payload: Object.assign({}, grp) });
      }
    }
    if (!found) {
      console.warn('Group not found:', payload.selectedGroupID);
      dispatch({
        type: 'set_selected_group',
        payload: {
          pvGroupID: payload.selectedGroupID,
          pvGroupName: '',
        },
      });
    }

    // Calculate the User Type

    if (payload.selectedGroupID === 1) {
      // State user (Always SCEMD on dev and prod)
      dispatch({ type: 'set_user_type', payload: 'STATE_USER' });
    } else {
      dispatch({ type: 'set_user_type', payload: 'COUNTY_USER' });
    }

    // let apps = await apiClient.get(
    //   `${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/accounts/apps/${payload.selectedGroupID}`,
    // );
    //
    // dispatch({ type: 'set_app_permissions', payload: apps.data.appsWithPermissions });
    //
    // if (apps.data && apps.data.apps) {
    //   const appArray = {};
    //   for (let curr of apps.data.apps) {
    //     const appData = {};
    //     Object.assign(appData, curr);
    //     appData.permissions = {};
    //     if (curr.appPermissions) {
    //       for (const p of curr.appPermissions) {
    //         appData.permissions[p] = true;
    //       }
    //     }
    //     appArray[curr.appName] = appData;
    //   }
    //   dispatch({ type: 'set_apps', payload: appArray });
    // }

    // Get users selected incidents
    let incidents = await getUsersSavedIncidents(payload);
    dispatch({ type: 'set_incidents', payload: incidents });

    setTimeout(() => {
      dispatch({ type: 'set_application_loading_state', payload: false });
    });
  };
}
