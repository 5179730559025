import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './DropFileZone.styles';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typo from '../Typo';
import { Button, FileContainer } from '../index';
import { bytesToSize, getProperKeyNameDummySearchString } from '../../../common/FileUploadAPI';
import { Box, LinearProgress } from '@mui/material';
import useUploadDocForm from '../../hooks/useUploadDocForm';

const DropFileZone = (props) => {
  const classes = useStyles();
  const baseStyle = classes.baseStyle;
  const focusedStyle = classes.focusedStyle;
  const acceptStyle = classes.acceptStyle;
  const rejectStyle = classes.rejectStyle;

  const {
    uploadForm,
    downloadForm,
    uploadedDocuments,
    progress,
    inProgress,
    setUploadedDocuments,
  } = useUploadDocForm();

  const [files, setFiles] = useState([]);
  const [filesDeleted, setFilesDeleted] = useState([]);
  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    // *: Get the uploaded files
    const fetchData = async (url) => {
      const config = {
        headers: { 'Content-Type': 'application/json' },
      };
      return await downloadForm(url, config);
    };

    if (props.uuid && !inProgress) {
      const url = `${process.env.REACT_APP_PALMETTO_ENDPOINT}/api/files?access_token=${
        sessionStorage.getItem('userAuthCreds') &&
        JSON.parse(sessionStorage.getItem('userAuthCreds')).id
      }&filter=${JSON.stringify({ where: { pvGlobalGroupID: props.uuid } })}`;
      fetchData(url).catch((err) => {
        console.error('🚀 Documents fetchData err: ', err);
      });
    }
  }, [props.uuid]);
  const onDrop = useCallback(async (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    const uploadFiles = [...files, ...acceptedFiles];
    props.filesQueuedCallback && props.filesQueuedCallback(() => uploadFiles);
    try {
      const tempUploadedFiles = [];
      for (let i = 0; i < uploadFiles.length; i++) {
        const file = uploadFiles[i];
        const url = `${
          process.env.REACT_APP_PALMETTO_ENDPOINT
        }/api/files/uploadWithoutID?access_token=${
          sessionStorage.getItem('userAuthCreds') &&
          JSON.parse(sessionStorage.getItem('userAuthCreds')).id
        }&filter=${JSON.stringify({ where: { pvGlobalGroupID: props.uuid } })}`;
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            uuid: props.uuid,
            userid: props.user.id,
            username: props.user.username,
          },
        };

        const uploadedFile = await uploadForm(file, url, config);
        if (uploadedFile.status === 200) tempUploadedFiles.push(uploadedFile.data);
      }
      setUploadedDocuments((prevFiles) => [...prevFiles, ...tempUploadedFiles]);
      props.filesQueuedCallback && props.filesQueuedCallback(() => tempUploadedFiles);
      props.filesUploadedCallback && props.filesUploadedCallback(tempUploadedFiles);
    } catch (error) {
      console.log('🚀onDrop Upload error: ', error);
      setUploadError(true);
    }
  }, []);

  const removeFile = (fileToRemove) => {
    let arr = uploadedDocuments;
    let fileToDelete = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].pvDataID === fileToRemove.pvDataID) {
        fileToDelete.push(arr[i]);
        arr.splice(i, 1);
      }
    }
    setUploadedDocuments((prevFiles) =>
      prevFiles.filter((file) => file.pvDataID !== fileToRemove.pvDataID),
    );
    const arr2 = [...filesDeleted, ...fileToDelete];
    setFilesDeleted(arr2);
    props.filesDeletedCallback && props.filesDeletedCallback(() => arr2);
    props.filesDeletedCB && props.filesDeletedCB(arr2);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <>
      {!inProgress && Array.isArray(uploadedDocuments) && uploadedDocuments.length > 0 ? (
        <Box sx={classes.btnContainer} onClick={open}>
          Add File
          <AddCircleIcon fontSize='small' />
        </Box>
      ) : null}
      {inProgress ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant='determinate' value={progress} />
        </Box>
      ) : null}
      <>
        {!inProgress && Array.isArray(uploadedDocuments) && uploadedDocuments.length > 0
          ? uploadedDocuments.map((file, idx) => {
              if (!file.pvVoid) {
                return (
                  <FileContainer
                    key={file?.pvDataID || idx}
                    name={getProperKeyNameDummySearchString(file?.cbrnDataFileName)}
                    bytes={bytesToSize(file?.cbrnDataFileSize)}
                    item={file}
                    removeFile={removeFile}
                  />
                );
              } else {
                return <></>;
              }
            })
          : null}
      </>
      {!inProgress && uploadedDocuments.length === 0 && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <CloudUploadIcon sx={{ color: '#C2C2C2', fontSize: '36px' }} />
          <Typo sx={classes.fileUploadTitle}>Drag & drop a file to upload</Typo>
          <Typo sx={classes.fileUploadSubTitle}>Or</Typo>
          <Button onClick={open}>BROWSE</Button>
        </div>
      )}
      {!inProgress && uploadError && (
        <Box sx={classes.errorContainer}>
          <Typo sx={classes.errorText}>Error uploading file</Typo>
        </Box>
      )}
    </>
  );
};

DropFileZone.prototype = {
  uuid: PropTypes.string,
  user: PropTypes.object,
  pvDataID: PropTypes.number,
  filesQueuedCallback: PropTypes.func,
  filesDeletedCallback: PropTypes.func,
  filesUploadedCallback: PropTypes.func,
  filesDeletedCB: PropTypes.func,
};

export default DropFileZone;
