import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { NormalTextField, RequiredTextField } from '@zawarski/palmetto-ui-components';
import { MdEdit as EditIcon, MdFactCheck as FactCheckIcon } from 'react-icons/md';

const Typo = ({ variant, ...props }) => {
  return (
    <Typography {...props} variant={variant}>
      {props.children}
    </Typography>
  );
};
const TextField = ({ errMsg, helperTxt, required, ...props }) => {
  const hasError = errMsg !== undefined && Object.prototype.hasOwnProperty.call(errMsg, 'message');
  const msgText = hasError ? errMsg.message : helperTxt;
  if (required) {
    return (
      <RequiredTextField
        {...props}
        style={{ marginLeft: 0, marginRight: 0 }}
        fullWidth
        InputLabelProps={{ shrink: true, color: hasError ? 'error' : 'secondary' }}
        helperText={msgText}
      />
    );
  }
  return (
    <NormalTextField
      {...props}
      style={{ marginLeft: 0, marginRight: 0 }}
      variant={props?.variant || 'standard'}
      fullWidth
      InputLabelProps={{ shrink: true, color: hasError ? 'error' : 'secondary' }}
      helperText={msgText}
    />
  );
};

const HeaderTabs = (props) => {
  const useStyles = () => ({
    container: {
      width: '100%',
      bgcolor: '#4778FF',
      padding: '8px',
    },
    tabs: {
      color: '#fff',
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .MuiTab-root': {
        textTransform: 'none', // Adjust text transformation
        fontWeight: 500, // Change font weight
        color: '#fff',
        height: '48px !important',
        margin: '0 !important',
        fontSize: '16px',
        minHeight: 'unset !important',
      },
      '& .MuiTab-root.Mui-selected': {
        backgroundColor: '#1C43B1 !important',
        color: '#fff !important',
        height: '48px !important',
        margin: '0 !important',
      },
    },
  });
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChange = (e, idx) => {
    setSelectedIndex(idx);
    // const tempOpts = props.options.filter((item) => !item.hidden);
    // props.onChange(tempOpts[idx].id);
    props.onChange(idx);
  };

  return (
    <Box sx={classes.container}>
      <Tabs
        sx={classes.tabs}
        value={selectedIndex}
        onChange={handleChange}
        centered
        variant='fullWidth'>
        <Tab value={0} label='South Carolina' />
        <Tab value={1} label='Risk Counties' />
        <Tab value={2} label='Host Counties' />
        <Tab value={3} label='Overview' iconPosition='start' icon={<FactCheckIcon />} />
      </Tabs>
    </Box>
  );
};

HeaderTabs.propTypes = {
  onChange: PropTypes.func,
};

const SectionContainer = (props) => {
  const useStyles = () => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      bgcolor: '#fff',
      width: '100%',
      padding: '24px',
      marginBottom: '16px',
      gap: '8px',
      mt: '60px',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      color: '#1C43B1',
      fontSize: '14px',
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    btn: {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'none',
      gap: '8px',
      fontSize: '12px',
      bgcolor: '#E0E0E0',
      color: '#1F1F1F',
      padding: '8px 16px',
      borderRadius: '4px',
      fontWeight: 600,
      cursor: 'pointer',
      '&:hover': {
        bgcolor: '#D8D8D8',
      },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '8px',
      bgcolor: '#EBEBEB',
      width: '100%',
      height: 'auto',
    },
  });
  const classes = useStyles();

  return (
    <Box sx={classes.container}>
      <Box sx={classes.titleWrapper}>
        <Typo sx={classes.title} variant={'body1'}>
          {props.title}
        </Typo>
        <Box sx={classes.btn}>
          Edit <EditIcon />
        </Box>
      </Box>
      <Box sx={classes.wrapper}>{props.children}</Box>
    </Box>
  );
};

SectionContainer.propTypes = {
  title: PropTypes.string,
};

export { Typo, HeaderTabs, SectionContainer };
