import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#C2C2C2',
    color: '#474747',
    padding: '10px 12px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = () => ({
  tableContainer: {
    height: '67vh',
    width: '100%',
    minWidth: '700px',
    overflow: 'auto',
    // '::-webkit-scrollbar': {
    //   display: 'none',
    // },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginY: '12px',
  },
});

export default useStyles;
