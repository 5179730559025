const pallete = {
  neutral: '#fff',
  primary: {
    light: '#5C87FF', // light blue
    main: '#2961FD', // main blue
    dark: '#1C43B1', // dark blue
  },
  secondary: {
    light: '#E0E0E0', // light grey
    main: '#E0E0E0', // main grey
  },
  text: {
    primary: '#000', // black
    secondary: '#1F1F1F', // dark grey
  },
};

export default pallete;
