import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import { Box, Button, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import { DropDownMenu, ErrorMessage, Loading, TextField } from '../../fragment';
import useRegisterForm, { defaultValues } from './hook/useRegisterForm';
import counties from '../../../redux/const/counties';
import useStyles from './SelfRegistration.styles';
import { useDispatch } from 'react-redux';
import { getLatestTraining, saveEntryAttendee } from '../../../redux/actions/training';
import { TrainingClassValue } from '../training/utils/ReactTableColumns';
import { FormatDate } from '../../utilities/utils';
import { useSnackbar } from 'notistack';
import { Attendees } from '../training/utils';

const SelfRegistration = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { control, handleSubmit, reset } = useRegisterForm();
  const [countyList, setCountyList] = useState([]);
  const [training, setTraining] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isTrainingAvailable, setIsTrainingAvailable] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    try {
      dispatch(
        getLatestTraining((err, res) => {
          if (!err) {
            if (!res) {
              setIsTrainingAvailable(false);
            } else {
              const training = {
                trainingType: TrainingClassValue(res.pvTraining),
                county: res.pvHostCounty,
                date: FormatDate(res.pvDate, 'MMMM DD, YYYY'),
                pvGroupID: res.pvDataID,
              };
              setTraining(training);
            }
          }
          setIsLoading(false);
        }),
      );
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsError(true);
    }
  }, []);
  useEffect(() => {
    if (counties) {
      const CombineOptionLists = [...counties, ...Attendees];
      const temp = [];
      CombineOptionLists.forEach((county) => {
        temp.push({ value: county, key: county });
      });
      setCountyList(temp.sort((a, b) => (a.value > b.value ? 1 : -1)));
    }
  }, [counties]);

  const onSubmit = async (data) => {
    const payload = {
      pvGroupID: training.pvGroupID,
      pvName: data.name,
      pvEmail: data.email,
      pvCounty: data.county,
      register: true,
    };
    setIsSaving(true);
    const resp = await saveEntry(payload);

    if (resp) {
      setIsSaving(false);
      enqueueSnackbar('Successfully registered', { variant: 'success' });
      reset(defaultValues);
    } else {
      enqueueSnackbar('Failed to register. Please contact support.', { variant: 'error' });
      setIsSaving(false);
      setIsError(true);
    }
  };

  function saveEntry(payload) {
    return new Promise((resolve, reject) => {
      try {
        dispatch(
          saveEntryAttendee(payload, (err, res) => {
            if (err) {
              return reject(err);
            } else {
              return resolve(res);
            }
          }),
        );
      } catch (error) {
        return reject(error);
      }
    });
  }

  return (
    <div className='layout vertical full-height'>
      <HeaderComponent title='Self Registration' />
      {isError ? (
        <ErrorMessage message='System has an ecountered an issue. Please contact our technical support.' />
      ) : isLoading ? (
        <Loading />
      ) : isTrainingAvailable ? (
        <Box sx={classes.container}>
          <Box sx={classes.header}>
            <Box sx={classes.headerTitle}>
              <Typography fontWeight={500} fontSize={20}>
                Class <span style={{ color: 'rgba(0, 0, 0, 0.40)' }}>Information</span>
              </Typography>
            </Box>
            <Typography fontSize={14} color={'#474747'}>
              Please fill out and submit this form to register your attendance for{' '}
              <b>{training?.trainingType}</b> that is being hosted by{' '}
              <b>{training?.county} County</b> on <b>{training?.date}</b>.
            </Typography>
          </Box>
          <Box sx={classes.content}>
            <Box sx={classes.formWrap}>
              <Grid container spacing={2}>
                <Grid xs={12} md={6} sx={{ pr: { xs: 0, md: 2 }, mb: 2 }}>
                  <Controller
                    control={control}
                    name='name'
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        error={Boolean(error)}
                        errMsg={error}
                        value={value}
                        onChange={onChange}
                        label='Name'
                        placeholder='Enter Name'
                        style={{ width: '100%' }}
                        required={true}
                        helperTxt={error ? error.message : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={6} sx={{ mb: 2 }}>
                  <Controller
                    control={control}
                    name='email'
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        error={Boolean(error)}
                        errMsg={error}
                        value={value}
                        onChange={onChange}
                        label='Email'
                        placeholder='Enter Email'
                        style={{ width: '100%' }}
                        required={false}
                        helperTxt={
                          error ? (
                            error.message
                          ) : (
                            <span style={{ color: 'var(--pcl-default-label-color)' }}>
                              Optional
                            </span>
                          )
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} sx={{ mb: 2 }}>
                  <Controller
                    control={control}
                    name='county'
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <DropDownMenu
                        id={'county'}
                        error={Boolean(error)}
                        label='From'
                        placeholder='Select County'
                        style={{ width: '100%' }}
                        values={countyList}
                        fullWidth
                        value={value}
                        onChange={onChange}
                        required={true}
                        helperTxt={error ? error.message : ''}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12}>
                  <Box sx={classes.btnWrap}>
                    <Button
                      variant='contained'
                      fullWidth
                      onClick={!isSaving ? handleSubmit(onSubmit) : undefined}>
                      {isSaving ? (
                        <>
                          <span style={{ paddingRight: '12px' }}>Please wait</span>
                          <CircularProgress size={24} color='neutral' />
                        </>
                      ) : (
                        'Register'
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={classes.container}>
          <Box sx={classes.header}>
            <Box sx={classes.headerTitle}>
              <Typography fontWeight={500} fontSize={20}>
                Class <span style={{ color: 'rgba(0, 0, 0, 0.40)' }}>Information</span>
              </Typography>
            </Box>
            <Typography fontSize={18} color={'#474747'} textAlign='center' sx={{ pt: '12px' }}>
              There is no training available at this time. Please check back later.
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default SelfRegistration;
